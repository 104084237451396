<template>
  <v-card class="pa-5">
    <v-card-title>
      <span class="headline">{{ $t('general.selectVisibleColumns') }}</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="3"
          v-for="header in headers.slice(1, headers.length)"
          :key="header.text"
        >
          <v-switch
            @change="saveChange(header.value)"
            :input-value="Lget(headersVisibleStore, header.value, !header.hide) !== true"
            :true-value="false"
            :false-value="true"
            :label="header.text" :value="header.value">
          </v-switch>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<script>
import Lget from 'lodash/get'

export default {
  name: 'TableColumnChooser',
  props: {
    headers: Array,
    headersVisibleStore: Object
  },

  methods: {
    Lget: Lget,
    saveChange: function (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>

<style scoped>

</style>
