<template>
    <v-menu
    v-model="openCalendar"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
    dark
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        dark
        v-model="value"
        v-bind:label="label"
        v-bind:prepend-icon="icon"
        v-on="on"
        readonly
      />
    </template>
    <v-date-picker light v-bind:value="value" @input="onInputCalendar" @change="save"/>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePickerInField',
  props: {
    label: String,
    value: String,
    icon: {
      type: String,
      default: 'fa-calendar-day'
    }
  },
  data () {
    return {
      openCalendar: false
    }
  },
  methods: {
    onInputCalendar (value) {
      this.openCalendar = !this.openCalendar
      this.$emit('input', value)
    },
    save () {
      this.$emit('save')
    }
  }
}
</script>

<style scoped>

</style>
