<template>
  <Layout :top-bar-title="title"
          :activeBackButton="false"
          :sections="sections"
          :baseLayoutInfoUrl="'/base-layout-info/'"
  >
    <CustomLayout
      :value="currentItem"
      @input="updateItem"
      :loading="loading"
      :breadcrumbs="currentItem.promo_template_items_breadcrumbs"
      :custom-view-show="isCurrentItemLoaded"
      :custom-view-name="customViewName"
      :custom-options="customViewOptions"
      :custom-component-catalog="customComponentCatalog"
      :actions="customActions"
      :action-selected.sync="actionSelected"
      :title="pageTitle"
    ></CustomLayout>

    <DialogConfirmation
            v-model="showConfirmationDialog"
            v-bind="confirmationProps"
            cancelOption
    ></DialogConfirmation>
  </Layout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import sections from './defaultNavigationDrawerSections'
import CustomLayout from '@/apps/core/components/CustomLayout'
import Layout from '@/apps/core/components/Layout'
import HierarchiesFiltersSelectorWrapper from '@/apps/core/components/HierarchiesFiltersSelectorWrapper/HierarchiesFiltersSelectorWrapper.vue'
import { BASE_URL, RESPONSE_LEVEL } from '@/variables'
import { createNotification } from '@/lib/unnotificationsqueue'
import DialogConfirmation from '@/lib/uncrudtable/components/forms/DialogConfirmation'

export default {
  name: 'PromoTemplateDetail',
  components: { Layout, CustomLayout, DialogConfirmation },
  data () {
    return {
      loading: false,
      isCurrentItemLoaded: false,
      showConfirmationDialog: false,
      actionSelected: {},
      sections: sections,
      customViewName: 'promo_template_detail',
      customComponentCatalog: {
        'HierarchiesFiltersSelectorWrapper': HierarchiesFiltersSelectorWrapper
      },
      confirmationProps: {
        titleDialog: this.$t('dialogs.creatingCampaign'),
        textDialog: this.$t('dialogs.redirectingToCampaign'),
        showButtons: false,
        loading: true
      }
    }
  },

  async created () {
    await this.getItem(this.$router.currentRoute.params.id)
    this.isCurrentItemLoaded = true
  },

  computed: {
    ...mapState('promoTemplates', {
      storeLoading: 'loading',
      currentItem: 'currentItem'
    }),
    ...mapState('campaignTemplates', { currentCampaignTemplate: 'currentItem' }),
    campaignTemplateId () {
      return (this.currentCampaignTemplate && this.currentCampaignTemplate.id) || this.currentItem.campaign_template
    },
    customActions: function () {
      return [
        {
          label: this.$t('general.delete'),
          method: this.onDelete,
          confirm: true,
          confirmOptions: { confirmText: this.$t('dialogs.confirmOption'), confirmColor: 'red' },
          icon: 'fa-times',
          color: 'red',
          dark: true,
          floatEnd: true,
          showButton: this.canEditPromo
        },
        {
          label: this.$t('campaigns.campaignTemplateCreateCampaign'),
          method: this.createCampaignFromTemplate,
          args: { redirect: false },
          validate: this.currentItem.can_edit,
          icon: 'fa-clone',
          color: 'secondary',
          showButton: typeof this.currentItem.can_create_campaign_from_template !== 'undefined'
            ? this.currentItem.can_create_campaign_from_template : false
        },
        {
          label: this.$t('customActions.saveAndContinue'),
          method: this.saveForm,
          args: { redirect: false },
          validate: true,
          icon: 'fa-save',
          color: 'primary',
          showButton: this.canEditPromo
        },
        {
          label: this.$t('general.save'),
          method: this.saveForm,
          args: { redirect: true },
          validate: true,
          icon: 'fa-save',
          color: 'primary',
          showButton: this.canEditPromo
        }
      ]
    },
    customViewOptions: function () {
      return {
        context: {
          promoId: this.currentItem.id,
          campaignId: this.campaignTemplateId,
          serverUrl: BASE_URL
        }
      }
    },
    title: function () {
      return this.storeLoading ? this.$t('promos.promoTemplateLoading')
        : this.currentItem ? this.$t('promos.promoTemplate', { description: this.currentItem.description || '' })
          : this.$t('promos.promoTemplateNotFound')
    },
    canEditPromo: {
      get () {
        return this.currentItem.can_edit_promo
      }
    },
    pageTitle () {
      return `${this.currentCampaignTemplate.description} - ${this.$t('promos.promoSimple')} ${this.currentItem.id}`
    }
  },

  methods: {
    ...mapActions({ addNotification: 'addNotification', dismissNotifications: 'dismissNotifications' }),
    ...mapActions('promoTemplates', ['getItem', 'patchItem', 'deleteItem', 'updateCurrentItem']),
    ...mapActions('campaignTemplates', ['createCampaignFromTemplateId']),

    updateItem (items) {
      this.updateCurrentItem({ ...this.currentItem, ...items })
    },

    async saveForm ({ redirect }) {
      this.loading = true
      // let currentItem = Object.assign({}, this.currentItem)
      // delete currentItem.cached_brand
      let response = await this.patchItem(this.currentItem.id)
      if (response.level === RESPONSE_LEVEL.SUCCESS) {
        if (redirect) {
          await this.$router.push({ name: 'CampaignTemplateDetail', params: { id: this.campaignTemplateId } })
        } else {
          this.addNotification(createNotification(response.message, RESPONSE_LEVEL.SUCCESS))
        }
      } else {
        this.addNotification(createNotification(response.message, response.level))
      }
      this.loading = false
    },

    async onDelete () {
      this.loading = true
      let response = await this.deleteItem(this.currentItem.id)
      if (response.level === RESPONSE_LEVEL.SUCCESS) {
        await this.$router.push({ name: 'CampaignTemplateDetail', params: { id: this.campaignTemplateId } })
      } else {
        this.addNotification(createNotification(response.message, response.level))
      }
      this.loading = false
    },

    async createCampaignFromTemplate () {
      this.loading = true
      if (await !this.currentItem.can_edit || await this.saveForm({ redirect: false })) {
        this.loading = true
        try {
          let response = await this.createCampaignFromTemplateId(this.currentItem.campaign_template)
          this.showConfirmationDialog = true
          this.addNotification(createNotification(response.message, response.level))
          const redirect = response.redirect_route_name
          const campaignId = response.redirect_route_url_params && response.redirect_route_url_params['id']
          let route = await this.$router.resolve({ name: redirect, params: { id: campaignId } })
          window.open(route.href, '_blank')
          location.reload()
        } catch (error) {
          this.addNotification(createNotification(error.message, error.level))
        } finally {
          this.showConfirmationDialog = false
          this.loading = false
        }
      }
    }
  }
}
</script>
