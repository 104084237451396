<template>
  <component @save="onSave" :is="dynamicComponent" :value="value" v-bind="$props"/>
</template>

<script>
import BooleanCell from './BooleanCell'
import DateRangePickerCell from './DateRangePickerCell'
import EditableCell from './EditableCell'
import MultiSelectorCell from './MultiSelectorCell'
import ObjectSelectorCell from './ObjectSelectorCell'
import ValueSelectorCell from './ValueSelectorCell'
import SimpleCell from './SimpleCell'
import DatePickerCell from './DatePickerCell'
import ChipCell from './ChipCell'
import AutoCompleteCell from './AutoCompleteCell'
import ColorPickerCell from './ColorPickerCell'
import FileInputCell from './FileInputCell'
import DynamicDialogTable from '../tables/DynamicDialogTable/DynamicDialogTable.vue'

import toInteger from 'lodash/toInteger'

export default {
  name: 'CustomCell',
  components: {
    BooleanCell,
    DateRangePickerCell,
    EditableCell,
    MultiSelectorCell,
    ObjectSelectorCell,
    ValueSelectorCell,
    SimpleCell,
    ChipCell,
    AutoCompleteCell,
    DynamicDialogTable
  },
  props: {
    id: String,
    nonEditableRow: Boolean,
    chip: Boolean,
    editable: Boolean,
    formatNumber: Boolean,
    decimalsNumber: Number,
    numberSeparator: String,
    isDate: Boolean,
    dateRangePicker: Boolean,
    datePicker: Boolean,
    selector: Boolean,
    multiSelector: Boolean,
    value: undefined, // any type for now,
    itemsToChoose: Array,
    returnObject: Boolean,
    isBoolean: Boolean,
    ellipsis: Boolean,
    autocomplete: Boolean,
    modelToUse: String,
    type: String,
    itemText: String,
    isColorPicker: Boolean,
    isFile: Boolean,
    customTextColor: String,
    backgroundColor: String,
    isDynamicDialogTable: Boolean,
    parentStoreModule: String,
    dynamicStoreModule: String,
    dynamicDescriptionValue: String,
    dynamicFilterContentKey: String,
    selectedItem: Object,
    itemKey: { type: String, default: 'id' },
    itemValue: { type: String, default: 'external_id' },
    urlType: String,
    linkURL: String
  },
  computed: {
    dynamicComponent: function () {
      if (this.nonEditableRow) {
        return SimpleCell
      }
      if (this.autocomplete) {
        return AutoCompleteCell
      } else if (this.isDynamicDialogTable) {
        return DynamicDialogTable
      } else if (this.chip) {
        return ChipCell
      } else if (this.dateRangePicker) {
        return DateRangePickerCell
      } else if (this.datePicker) {
        return DatePickerCell
      } else if (this.selector) {
        if (this.returnObject) {
          return ObjectSelectorCell
        } else {
          return ValueSelectorCell
        }
      } else if (this.multiSelector) {
        return MultiSelectorCell
      } else if (this.isBoolean) {
        return BooleanCell
      } else if (this.isColorPicker) {
        return ColorPickerCell
      } else if (this.editable) {
        return EditableCell
      } else if (this.isFile) {
        return FileInputCell
      } else {
        return SimpleCell
      }
    }
  },
  mounted () {
    this.activateCustomBackground()
  },
  methods: {
    /** We just pass up the save event from the specific cell component **/
    onSave (val) {
      let casted = val
      switch (this.type) {
        case 'string':
          String(val)
          break
        case 'float':
          casted = parseFloat(val)
          break
        case 'integer':
          casted = toInteger(val)
          break
      }
      this.$emit('save', casted)
    },
    activateCustomBackground () {
      if (this.backgroundColor) {
        let elemStyle = document.getElementById(this.id).parentElement.style
        if (!elemStyle['background-color'] || elemStyle['background-color'] !== this.backgroundColor) {
          elemStyle['background-color'] = this.backgroundColor
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
