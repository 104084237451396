<template>
  <v-card v-if="!isValue" style="width: 100% !important;" class="pa-5 h-custom" outlined>
    <v-col class="d-flex justify-center align-center">
      <span :style="{'font-size': '2rem', 'font-weight': 'bold', 'color': value_color}">
        {{value}}
      </span>
    </v-col>
    <v-col class="d-flex justify-center align-center pt-3 pb-0">
      <v-icon color="black" style="margin-right: 1vw; font-size: 18px !important;">
        {{ icon }}
      </v-icon>
      <span style="font-size: 1.2rem;">
        {{title}}
      </span>
    </v-col>
  </v-card>
  <v-card v-else height="180" class="flex-grow-1">
    <v-skeleton-loader type="avatar, heading" class="d-flex flex-column align-center"
                       :height="'100%'" :style="'justify-content: space-evenly'"/>
  </v-card>
</template>

<script>
export default {
  name: 'KpiCard',
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    icon: {
      type: String,
      default: 'fa-chart-bar'
    },
    value_color: {
      type: String
    }
  },
  computed: {
    isValue () {
      return this.value === undefined || (typeof this.value === 'string' && this.value.includes('undefined'))
    }
  }
}
</script>

<style scoped>
  .h-custom {
    height: 144px;
  }
</style>
