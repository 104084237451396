import { mapState } from 'vuex'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { createNotification } from '@/lib/unnotificationsqueue'
import { RESPONSE_LEVEL } from '@/variables'
import { unNumberFmt } from '@/lib/uncrudtable/mixins/filters'
import hierarchiesFiltersList from '@/apps/core/store/hierarchiesFiltersList'

export default {
  name: 'HierarchiesFiltersList',
  components: {
    CrudTable
  },
  props: {
    selectedItems: {
      type: Array,
      description: 'Elements in treeDataBottom to be used in fetch',
      required: true
    },
    entity: {
      type: String,
      description: 'Label of entity on which hierarchy tree is based (i.e. Clientes, Productos)',
      required: true
    },
    listEndpoint: {
      type: String,
      description: 'Endpoint from UnCustomUI parameters to be used as fetch URL',
      required: true
    }
  },
  data () {
    return {
      commonModule: 'hierarchiesFiltersList',
      showModal: false,
      tableOptions: {
        'sortBy': ['id'],
        'sortDesc': [true]
      },
      tableHeaders: [
        { text: 'Id', value: 'external_id' },
        { text: 'Nombre', value: 'name' }
      ]
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig },
      loading: function (state) { return state[this.storeModule].loading },
      totalCount: function (state) { return state[this.storeModule].totalCount }
    }),
    headers () {
      if (this.crudTableConfig.headers?.length) {
        return this.crudTableConfig.headers
      } else {
        return this.tableHeaders
      }
    },
    storeModule () {
      return `${this.commonModule}${this.entity}`
    },
    /**
   * Generates "include" and "exclude" query-params for fetch.
   * @returns {string}
   */
    generateQuery () {
      return `?include=${this.parsedItems.include}&exclude=${this.parsedItems.exclude}`
    },
    /**
   * Filters the items and returns two arrays of ids according to their 'included' state
   * @returns {Object} contains arrays 'include' and 'exclude'
   */
    parsedItems () {
      let parsed = { include: [], exclude: [] }
      this.selectedItems.forEach(item => {
        (item.data.included) ? parsed.include.push(item.id) : parsed.exclude.push(item.id)
      })
      return parsed
    },
    /**
   * Returns "total" count message for dialog
   * @returns {string}
   */
    totalCountMessage () {
      return this.loading ? `${this.$t('general.loadingNotDots')} ${this.entity}...` : `${this.entity} ${this.$t('general.selected')}: ${this.formattedTotalCount}`
    },

    formattedTotalCount () {
      return unNumberFmt(this.totalCount, 0)
    }
  },
  watch: {
    async parsedItems () {
      await this.fetchList()
    }
  },
  created () {
    if (!(this.$store && this.$store.state && this.$store.state[this.storeModule])) {
      this.$store.registerModule(this.storeModule, hierarchiesFiltersList)
    }
  },
  methods: {
    /**
   * Display distributor/clients modal
   */
    toggleModal () {
      if (!this.loading) {
        this.showModal = !this.showModal
      }
    },
    /**
   * Fetches list of elements according to specified filters. Shows alert if no filter is "included"
   * @returns {Promise<void>}
   */
    async fetchList () {
      if (!this.parsedItems.include.length) {
        this.$store.commit(`${this.storeModule}/clearItems`)
      } else {
        this.$store.commit(`${this.storeModule}/setLoading`, true) // this.loading = true
        try {
          await this.$store.dispatch(`${this.storeModule}/getItemList`, {
            listEndpoint: this.listEndpoint,
            queryParams: { include: this.parsedItems.include.join(','), exclude: this.parsedItems.exclude.join(',') }
          })
        } catch (err) {
          await this.$store.dispatch(`${this.storeModule}/clearItems`)
          if (err) {
            let alert = (err.level && err.message) ? err : { message: this.$t('general.basicError'), level: RESPONSE_LEVEL.ERROR }
            await this.$store.dispatch('addNotification', createNotification(alert.message, alert.level))
          }
        } finally {
          this.$store.commit(`${this.storeModule}/setLoading`, false) // this.loading = false
        }
      }
    }
  }
}
