<template>
  <div class="align-right" style="width: 210px; float:right;">
    <DatePickerInField label="fecha" :value="this.value" @input="onInput"/>
  </div>
</template>

<script>
/**
   * This component is used to create a DatePicker inside a cell
   */
import DatePickerInField from '../forms/DatePickerInField'

export default {
  name: 'DatePickerCell',
  components: { DatePickerInField },
  props: {
    value: [String, Number, Boolean]
  },
  computed: { },
  methods: {
    onInput: function (val) {
      this.$emit('save', val)
    }
  }
}
</script>

<style scoped>

</style>
