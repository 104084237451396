<template>
  <div class="d-flex height-40 justify-start align-center">
    <v-select dense outlined label="Acción" :items="enabledActions" v-model="selectedAction" return-object hide-details background-color="white" class="mr-4 mt-3 mw-300"/>
    <v-btn class="mt-3" min-height="40" background-color="white" color="primary" outlined @click="$emit('applyAction', selectedAction)" v-text="$t('general.apply')" />
    <v-subheader class="mt-3" v-text="totalItemsSelect" />
  </div>
</template>

<script>
import { isNull, isUndefined } from 'lodash'

export default {
  name: 'TableActions',
  props: {
    actions: Array,
    selected: Array,
    total: Number,
    editableValues: Boolean
  },
  data () {
    return {
      selectedAction: null,
      enabledActions: [...this.actions.filter(el => (this.editableValues || el.always))]
    }
  },
  computed: {
    totalItemsSelect () {
      return `${this.selected.length} ${this.$tc('general.elementsSelectedAndTotal', this.total)}`
    }
  },
  watch: {
    editableValues (newVal, oldVal) {
      this.enabledActions = this.actions.filter(el => {
        return isNull(el.always) || isUndefined(el.always) || !el.always ? newVal : el.always
      })
    }
  }
}
</script>

<style scoped>
.mw-300 {
  max-width: 300px;
}

.height-40 {
  height: 40px;
}
</style>
