/** Mixin that encapsulates request monitoring for use-cases where multiple simultaneous calls are possible
 *  Computes currently unresolved/pending requests, in order to show loader/spinner according to correct state
 *
 *  Implementation:
 *    The mixin's onMonitorRequest must wrap the apiClient call or action, this way the monitors' promise
 *    is called first, and the isPendingRequests computed property can be used to control component state
 *
 *  onSaveMethod (data) {
 *    this.onMonitorRequest(this.postItem(data))
 *      .then((response) => {
 *        this.doSomething(response)
 *      })
 *      .catch((error) => {
 *        if (!this.isPendingRequests) {
 *          this.getItem()
 *        }
 *      })
 *  }
 *
 *  PENDING: Support for monitoring multiple functions in one component
 *  As is currently implemented, a component can only monitor one function/call per component;
 *  Since it only has one 'pendingRequests' array and, if another request must be monitored,
 *  there is no way to distinguish to which function belongs each pendingRequest:
 *
 *  POSSIBLE SOLUTION: is it possible to distinguish
 */

export const requestsMonitorsMixin = {

  data () {
    return {
      pendingRequests: []
    }
  },

  computed: {
    isPendingRequests () {
      return this.pendingRequests.length > 0
    }
  },

  methods: {
    onMonitorRequest (promise) {
      let timestamp = new Date().getTime()
      this.pendingRequests.push(timestamp)
      return promise.finally(response => {
        let timestampIndex = this.pendingRequests.indexOf(timestamp)
        this.pendingRequests.splice(timestampIndex, 1)
        return response
      })
    }
  }
}
