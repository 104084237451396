import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState } from 'vuex'

@Component({
  components: {
    DynamicTable: () => import('../../CrudTable')
  },
  props: {
    parentStoreModule: {
      type: String,
      default: null
    },
    selectedItem: {
      type: Object,
      default: null
    },
    dynamicStoreModule: {
      type: String,
      default: null
    },
    dynamicDescriptionValue: {
      type: String,
      default: null
    },
    dynamicFilterContentKey: {
      type: String,
      default: null
    },
    defaultQueryParameters: {
      type: Object,
      default: null
    },
    showSelectPanel: {
      type: Boolean,
      default: true
    },
    showActionsPanel: {
      type: Boolean,
      default: false
    },
    showSearchPanel: {
      type: Boolean,
      default: true
    },
    showAddItemsCustom: {
      type: Boolean,
      default: false
    },
    addItems: {
      type: Boolean,
      default: false
    },
    activeSingleSelect: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.dynamicStoreModule].crudTableConfig },
      loading: function (state) { return state[this.dynamicStoreModule].loading }
    })
  }
})

class DynamicDialogTable extends Vue {
  dialog = false
  item = null
  tableOptions = {
    'sortBy': ['id'],
    'sortDesc': [true],
    filterFields: {
      [this.dynamicFilterContentKey]: [this.selectedItem.id]
    }
  }

  get description () {
    const globalSelectedItems = this.$store.getters[`${this.parentStoreModule}/getDynamicDialogSelectedItems`]
    let description = '-'
    if (globalSelectedItems.length > 0 && this.item !== null) {
      const selectedItem = globalSelectedItems.find(item => this.item.id === item.id)
      if (selectedItem) {
        description = selectedItem
        this.dynamicDescriptionValue.split('__').forEach(element => {
          description = description[element]
        })
      }
    }
    return description
  }

  get buttonsStyle () {
    return this.showActionsPanel ? 'margin: -11vh 1vw 0 0' : 'margin: 1vh 1vw 0 0'
  }

  onSelectItem (value) {
    this.item = value[0]
    this.item['parentId'] = this.selectedItem.id
  }

  async onSaveSelectedItem () {
    await this.$store.commit(`${this.parentStoreModule}/addDynamicDialogSelectedItem`, this.item)
    this.dialog = false
  }
}

export default DynamicDialogTable
