<template>
  <v-container>
    <v-row v-if="!loading">
      <v-col class="mb-n2" v-for='customBlockData in orderedCustomBlockList' :key='customBlockData.id' :cols='customBlockData.width'>
        <!-- Normal card -->
        <v-card v-if="!customBlockData.is_expandable"
                :class="{'removePadding': customBlockData.flat,'mb-0 pa-0': !customBlockData.flat, 'elevation-1': !customBlockData.flat}"
                :flat="customBlockData.flat"
                :style="customBlockData.width < 12 ? 'height: 100%' : ''"
        >
          <v-card-title v-if="customBlockData.title && !customBlockData.flat">
            <v-icon color="accent" left>
              {{ customBlockData.title_icon }}
            </v-icon>
            <span class='heading5 ml-1 my-n1'>{{ customBlockData.title }}</span>

            <v-tooltip bottom v-if="customBlockData.admin_url">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :href="customBlockData.admin_url" target="_blank" color="purple" outlined small class="elevation-1 ml-3">
                  <v-icon>fa-cube</v-icon>
                </v-btn>
              </template>
              <span>Custom Block Admin</span>
            </v-tooltip>

          </v-card-title>
          <div v-else-if="customBlockData.title" class="ml-4 pt-4 d-flex">
            <v-icon color="accent" left>
              {{ customBlockData.title_icon }}
            </v-icon>
          <span
                class="headline ml-1"
          >
              {{ customBlockData.title }}
          </span>
          </div>
          <v-card-text>
            <CustomBlock
                :value="value"
                @input="$emit('input', $event)"
                :custom-block-data="customBlockData"
                :custom-component-catalog="fullComponentCatalog"
                :options="options"
                :viewLoading="viewLoading"
            />
          </v-card-text>
        </v-card>
        <!-- expandable card -->
        <v-expansion-panels v-if="customBlockData.is_expandable">
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-3">
              <v-card-title>
                <v-icon color="accent" left>
                  {{ customBlockData.title_icon }}
                </v-icon>
                <span class='heading5 ml-1 mt-n3 mb-n3'>{{ customBlockData.title }}</span>
                <v-btn v-if="customBlockData.admin_url" :href="customBlockData.admin_url" target="_blank" color="purple" outlined small class="elevation-1 ml-3">
                  <v-icon>fa-cube</v-icon>
                </v-btn>
              </v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content dense>
              <CustomBlock
                  :value="value"
                  @input="$emit('input', $event)"
                  :custom-block-data="customBlockData"
                  :custom-component-catalog="fullComponentCatalog"
                  :options="options"
                  :viewLoading="viewLoading"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center">
        <v-progress-circular indeterminate color="primary"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import apiClient from '@/lib/unlogin/store/apiclient'
import CustomBlock from './CustomBlock/CustomBlock'
import { BASE_URL, IS_PNG } from '@/variables'
import CustomKpisBlock from '@/lib/uncustomui/components/CustomKpisBlock/CustomKpisBlock'

export default {
  name: 'CustomView',
  components: { CustomBlock },
  props: {
    viewName: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    options: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    customComponentCatalog: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    viewLoading: Boolean
  },
  data () {
    return {
      customBlockList: [],
      loading: true,
      builtInCustomComponents: { 'CustomKpisBlock': CustomKpisBlock }
    }
  },
  computed: {
    orderedCustomBlockList: function () {
      return _.orderBy(this.customBlockList, 'order')
    },
    fullComponentCatalog: function () {
      return { ...this.builtInCustomComponents, ...this.customComponentCatalog }
    }
  },
  /**
   * Emits 'load' event to parent view when CustomView's API fetch is done
   */
  mounted () {
    let loadEvent = 'load:'
    apiClient.get('/custom_view/' + this.viewName)
      .then(response => {
        this.loading = true
        if (response.data.admin_url) this.$emit('adminUrlCustomView', BASE_URL + response.data.admin_url)
        this.customBlockList = response.data.custom_blocks.map(customBlock => {
          customBlock.admin_url = customBlock.admin_url ? BASE_URL + customBlock.admin_url : null
          return customBlock
        })
        loadEvent += 'success'
      })
      .catch(() => {
        loadEvent += 'error'
      })
      .finally(() => {
        this.loading = false
        this.$emit(loadEvent)
      })
  },

  beforeDestroy () {
    this.$off('adminUrlCustomView')
  },

  methods: {
    capitalizeStr: function (value) {
      return value !== null && IS_PNG ? value.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : value
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
