export default {
  name: 'HierarchiesBreadcrumb',
  props: {
    breadcrumb: {
      type: Array,
      description: 'List of elements that compose the breadcrumb',
      required: true
    }
  }
}
