<template>
  <div class="primary--text text--darken-2">
    <template>
      <v-select
        :items="items"
        :value="value"
        :item-text="itemValue"
        :item-value="itemKey"
        style="border-color: #309DF3"
        class="my-custom-style"
        v-on:change="save"
      />
    </template>
  </div>
</template>

<script>
/**
   * This component is used to create a selector inside a cell, you must pass some parameters in order to work properly.
   * @itemsToChoose This is the items that will be show in the selector. This items must have external id and name.
   * @label_name The name to show as default in the selector.
   *
   */
import { filtersMixin } from '../../mixins/filters'
import Lget from 'lodash/get'

export default {
  name: 'ValueSelectorCell',
  mixins: [filtersMixin],
  props: {
    value: [String, Number],
    name: String,
    modelToUse: String,
    itemKey: { type: String, default: 'value' },
    itemValue: { type: String, default: 'text' },
    customColors: { type: Object }
  },
  data () {
    return {}
  },
  computed: {
    items: function () {
      if (this.modelToUse) {
        if (this.modelToUse.includes('/')) {
          return this.$store.getters[this.modelToUse]
        } else {
          return Lget(this.$store.state, this.modelToUse, [])
        }
      } else {
        return []
      }
    }
  },
  methods: {
    save: function (value) {
      this.$emit('save', value)
    }
  }
}
</script>

<style scoped>
.my-custom-style >>>.v-select__selection--comma {
  color: #309DF3 !important;
  font-weight: bold;
}
</style>
