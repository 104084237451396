<template>
  <div class="primary--text text--darken-2">
    <template>
      <v-select
        :value="value"
        :items="items"
        :item-value="itemValue"
        item-text="name"
        chips
        multiple
        autofocus
        dense
        @keyup.enter.native="save"
        @change="save"
        return-object
      />
    </template>
  </div>
</template>

<script>
/**
   * This component is used to create a selector inside a cell, you must pass some parameters in order to work properly.
   * @itemsToChoose This is the items that will be show in the selector. This items must have external id and name.
   * @label_name The name to show as default in the selector.
   *
   */
import { filtersMixin } from '../../mixins/filters'
import Lget from 'lodash/get'

export default {
  name: 'MultiSelectorCell',
  mixins: [filtersMixin],
  props: {
    value: [String, Number, Boolean, Array],
    modelToUse: String,
    itemValue: { type: String, default: 'external_id' }
  },
  data () {
    return {}
  },
  computed: {
    items: function () {
      if (this.modelToUse) {
        if (this.modelToUse.includes('/')) {
          return this.$store.getters[this.modelToUse]
        } else {
          return Lget(this.$store.state, this.modelToUse, [])
        }
      } else {
        return []
      }
    }
  },
  methods: {
    save: function (val) {
      this.$emit('save', val)
    }
  }
}
</script>

<style scoped>

</style>
