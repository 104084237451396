<template>
  <div class="primary--text text--darken-2">
    <template>
      <v-container>
        <v-row>
          <v-col style="padding: 3px">
            <v-file-input
              counter
              placeholder="Suba un fichero"
              show-size
              type="file"
              ref="myfile"
              v-on:change="save"
              v-model="internalFile"
            />
          </v-col>
          <v-col style="padding: 3px; padding-top: 8px;" class="d-flex justify-center">
            <v-tooltip color="black" dark left>
              <template v-slot:activator="{ on }">
                <v-btn v-show="valueToShow.length > 0" :href="value" color="primary" target="_blank" fab small dark v-on="on">
                  <v-icon>fas fa-file-download</v-icon>
                </v-btn>
              </template>
              <span>{{valueToShow}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
/**
   * This component is used to create a file selector inside a cell
   */
import { filtersMixin } from '../../mixins/filters'
import { split } from 'lodash'

export default {
  name: 'FileInputCell',
  mixins: [filtersMixin],
  props: {
    value: undefined,
    editable: Boolean
  },
  data () {
    return {
      internalFile: null
    }
  },
  computed: {
    valueToShow () {
      return this.getFileName(this.value)
    }
  },
  methods: {
    save: function (value) {
      // Emit the new file and change the values to show of route and value to show
      this.$emit('save', value)
    },
    getFileName: function (v) {
      return v ? this.serializeFileName(v.split('/').slice(-1)[0]) : ''
    },
    serializeFileName (filename) {
      // Function to eliminate white space encoded characters
      return split(filename, '%20').join(' ')
    }
  }
}
</script>

<style scoped>

</style>
