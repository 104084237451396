import { IS_PNG } from '@/variables'
import DialogConfirmation from '@/lib/uncrudtable/components/forms/DialogConfirmation.vue'
import HierarchiesFiltersSelector from '../HierarchiesFiltersSelector/HierarchiesFiltersSelector.vue'
import HierarchiesFiltersList from '../HierarchiesFiltersList/HierarchiesFiltersList.vue'
import { parseFormattedUrl } from '@/apps/core/helpers/utils'

export default {
  name: 'HierarchiesFiltersSelectorWrapper',
  components: {
    HierarchiesFiltersSelector,
    HierarchiesFiltersList,
    DialogConfirmation
  },
  props: {
    entity: {
      type: String,
      description: 'Label of entity on which hierarchy tree is based (i.e. Clientes, Productos)',
      required: true
    },
    currentItemIdKey: {
      type: String,
      default: 'id'
    },
    endpoints: {
      type: Object,
      description: 'Endpoints object for specific hierarchy API calls',
      required: true
    },
    selectableLevels: {
      type: Object,
      description: 'Defines which hierarchyElements are selectable depending on their level.' +
        'If prop is undefined, every level is selectable (empty array is set as default) (?)' +
        '{ "hierarchyExternalId": { min: levelId, max: levelId }, ... }',
      default: () => {}
    },
    value: {
      type: Object,
      description: 'Value prop from UnCustomUI, references item to be in sync with CustomBlocks (i.e. currentItem)',
      required: true
    },
    doFetchFiltersList: {
      type: Boolean,
      description: 'Value prop from UnCustomUI, used when the Distributor/ProductFiltersList is needed elsewhere' +
        '(Used in P&G PromoDetail, to be used by the Special Packs & Display dialog)',
      default: false
    },
    customStoreModule: {
      type: String,
      default: 'hierarchiesFilterSelectorStore'
    },
    doNotCommitHierarchyFilters: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showHierarchiesDialog: false,
      showConfirmationDialog: false,
      search: null,
      tableHeaders: [],
      isPaginationHiding: false,
      pendingChanges: false
    }
  },
  async created () {
    await this.getHierarchiesData()
  },
  computed: {
    /**
     * True for PNG
     * @returns {boolean}
     */
    isPng () {
      return IS_PNG
    },
    /**
     * ConfirmationDialog config
     */
    confirmationProps () {
      return {
        titleDialog: this.$t('dialogs.saveMadeChanges'),
        textDialog: this.$t('dialogs.changesQuestion'),
        confirmText: this.$t('dialogs.saveConfirm'),
        cancelText: this.$t('dialogs.dontSaveAndClose')
      }
    },
    /**
     * Formats the hierarchy filters for the datatable
     * @returns {Object}
     */
    formattedFilters () {
      return this.filters.map((element) => {
        return {
          ...element,
          included: element.included === true ? this.$t('general.yes') : 'No',
          breadcrumb: element.breadcrumb.join(' / ').toUpperCase()
        }
      })
    },
    /**
     * Returns 'hierarchyFilters' endpoint formatted with the current entity id (i.e. Campaign, Promo)
     * @returns {String}
     */
    hierarchyFiltersEndpoint () {
      if (!this.doNotCommitHierarchyFilters) {
        return parseFormattedUrl(this.endpoints.hierarchyFilters, { id: this.value[this.currentItemIdKey] })
      } else {
        return ''
      }
    },
    headers () {
      return this.$store.getters[`${this.customStoreModule}/getHierarchyHeaders`]
    },
    loadingFilters () {
      return this.$store.getters[`${this.customStoreModule}/getLoadingFilters`]
    },
    filters () {
      return this.$store.getters[`${this.customStoreModule}/getFilters`]
    },
    selectedFilters () {
      return this.filters.map(element => {
        return {
          id: element.hierarchy_element,
          data: { 'textToSearch': element.breadcrumb.join('/'), 'breadcrumb': element.breadcrumb, 'included': element.included }
        }
      })
    }
  },
  beforeDestroy () {
    this.$store.commit(`${this.customStoreModule}/clearFilters`)
  },
  methods: {
    /**
     * Fetches existing filters and saves them at the store
     */
    async getHierarchiesData () {
      if (!this.doNotCommitHierarchyFilters) {
        await this.$store.dispatch(`${this.customStoreModule}/getHierarchyFilters`, this.hierarchyFiltersEndpoint)
      }
      if (this.doFetchFiltersList) {
        this.$store.commit('productsFilter/setHierarchyListEndpoint', this.endpoints.hierarchicalElement)
        /**
         await this.$store.dispatch(`${this.customStoreModule}/getHierarchyFiltersList`, {
        listEndpoint: this.endpoints.hierarchicalElement
      })
         */
      }
      this.paginationVisibility()
    },
    /**
     * Toggles the HierarchiesFilterSelector dialog on or off
     * @returns {boolean}
     */
    onToggleDialog () {
      this.showHierarchiesDialog = !this.showHierarchiesDialog
    },
    /**
     * Called to save upon dialog-closing confirmation, further triggers save event (and hence onSaveFilters)
     * @returns {Promise<void>}
     */
    async onAcceptConfirmation () {
      await this.$refs.component.saveFilters()
    },
    /**
     * Called whenever new Hierarchy filters are saved, in order to refresh the table
     * @returns {Promise<void>}
     */
    async onSaveFilters () {
      this.onToggleDialog()
      if (!this.doNotCommitHierarchyFilters) {
        await this.getHierarchiesData()
      }
    },
    paginationVisibility () {
      this.formattedFilters.length <= this.$refs.tableRef && this.$refs.tableRef.itemsPerPage ? this.isPaginationHiding = true : this.isPaginationHiding = false
    },
    exitModal () {
      if (this.pendingChanges) {
        this.showConfirmationDialog = true
      } else {
        this.onToggleDialog()
      }
    },
    async refreshHierarchiesFiltersList () {
      await this.$refs.hierarchiesFiltersList.fetchList()
    },
    hasPendingChanges (changes) {
      this.pendingChanges = Object.values(changes).some(change => change !== 0)
    }
  }
}
