<template>
  <span v-if="editable">
    <v-edit-dialog
      large
      persistent
      @save="save"
      cancel-text="Cancelar"
      save-text="Aceptar"
    >
      <v-chip :color="value" >{{ value }}</v-chip>
      <template v-slot:input>
        <v-color-picker :disabled="!editable" class="mt-5" v-model="internalValue" mode="hexa"></v-color-picker>
      </template>
    </v-edit-dialog>
  </span>
  <span v-else>
    <v-chip :color="value" >{{ value }}</v-chip>
  </span>
</template>

<script>
import { filtersMixin } from '../../mixins/filters'

const commaToDot = v => v.replace(',', '.')

export default {
  name: 'ColorPickerCell',
  mixins: [filtersMixin],
  props: {
    value: String,
    editable: Boolean,
    name: String,
    formatNumber: Boolean,
    type: String
  },
  data () {
    return {
      internalValue: '#ffffff',
      validateRule: v => !isNaN(commaToDot(v)) || (!this.formatNumber || 'Valor no válido')
    }
  },
  computed: {
    color: {
      get () {
        return this.value
      },
      set (v) {
        console.log(v)
        this.internalValue = v
      }
    }

  },
  methods: {
    save: function () {
      this.$emit('save', this.internalValue)
    }
  }
}
</script>

<style scoped>

</style>
