<template>
  <div class="primary--text text--darken-2">
    <template>
      <v-checkbox class="my-n5 pt-5"
        v-model="computedValue"
        v-on:change="save"
        :disabled="!editable"
      />
    </template>
  </div>
</template>

<script>
/**
   * This component is used to create a checkbox inside a cell
   */
import { filtersMixin } from '../../mixins/filters'

export default {
  name: 'BooleanCell',
  mixins: [filtersMixin],
  props: {
    value: Boolean,
    editable: Boolean
  },
  computed: {
    // for some reason v-checkbox needs v-model and we cannot just pass a value
    computedValue: {
      get () {
        return this.value
      },
      // eslint-disable-next-line no-unused-vars
      set (_) {
        // on-change already emits  this.$emit('save', value)
      }
    }
  },
  methods: {
    save: function (value) {
      this.$emit('save', value)
    }
  }
}
</script>

<style scoped>

</style>
