<template>
  <div v-resize="onResize" class="CrudTable" tabindex="0" @keypress.enter="launchFilters">
    <h1 v-if="title" class="display-1 ml-0 pl-0">{{title}}</h1>
    <v-container style="max-width: 100%;" class="pt-0 pb-0" >
      <v-layout row wrap>
        <!-- Filters -->
        <v-row>
          <v-col
            v-for="filter in filterItems"
            :key="filter.value"
            class="d-inline-flex pt-0 pb-0"
            cols="2"
          >
            <v-autocomplete
              v-if="filter.type === 'string'"
              :items="filter.items"
              :key="filter.label"
              :disabled="loading"
              :label="filter.label"
              :ref="filter.label"
              v-model="options.filterFields[filter.field]"
              item-value="key"
              item-text="value"
              clearable
              multiple
            >
              <!-- "(x others)" slot. -->
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0" close @click:close="deleteSingleFilter(filter.field, item.key)">
                  <span >{{ item.value }}</span>
                </v-chip>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption ml-2"
                >
                  (+{{ options.filterFields[filter.field].length - 1 }} {{ $tc('general.others', options.filterFields[filter.field].length - 1) }})
                </span>
              </template>

              <!-- "Select All" slot. -->
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="(item) => filterSelectAllClick(filter)"
                >
                  <v-list-item-action>
                    <v-icon
                      large
                      :color="getFilterValues(filter.field).length > 0 ? 'primary' : ''"
                    >
                      {{ getSelectAllIcon(filter) }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{$t('general.selectAll')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-autocomplete>

            <DateRangePickerInField
              dense
              v-if="filter.type === 'date'"
              :key="filter.label"
              :disabled="loading"
              :label="filter.label"
              :start.sync="dates[`${filter.label}StartDate`]"
              :end.sync="dates[`${filter.label}EndDate`]"
              :initialValue="filter.initialValues"
              @save="() => selectFilter([dates[`${filter.label}StartDate`], dates[`${filter.label}EndDate`]].join(','), filter.field, filter.type)"
            />
          </v-col>
        </v-row>
      </v-layout>
      <v-layout row wrap>
        <div class="row wrap mb-n1">
          <v-text-field
            dense
            v-if="showSearchPanel"
            class="ml-3 mb-3"
            v-model="search"
            :label="$t('general.search')"
            append-icon="fa-search"
            @input="searchDebounce"/>
          <v-spacer></v-spacer>
          <div>
            <v-tooltip bottom v-if="customTableAdminUrl">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :href="tableAdminURL" target="_blank" color="purple" outlined class="mb-2 mr-2 elevation-1">
                  <v-icon>fa-table</v-icon>
                </v-btn>
              </template>
              <span>Custom Table Admin</span>
            </v-tooltip>

            <v-tooltip bottom v-if="modelDataAdminUrl">
              <template v-slot:activator="{ on }">
                 <v-btn v-on="on" :href="tableModelDataAdminURL" target="_blank" color="purple" outlined class="mb-2 mr-2 elevation-1">
                <v-icon>fa-database</v-icon>
            </v-btn>
              </template>
              <span>Data List Admin</span>
            </v-tooltip>

            <v-dialog v-if="showSearchPanel"
                      v-model="columnChooserDialog"
                      max-width="800px"
            >
              <template v-slot:activator="{ on }">
                <v-btn color="primary" outlined dark class="mb-2 mr-2" v-on="on">
                  <v-icon>fa-cog</v-icon>
                </v-btn>
              </template>
              <TableColumnChooser :dialog.sync="columnChooserDialog" :headers="headers" :headersVisibleStore="headersVisibleStore"
                                  @input="toggleVisibleCol"/>
            </v-dialog>

            <v-btn v-if="filterItems.length" dark class="mb-2 mr-2 elevation-1" color="primary" @click="launchFilters">
               <v-icon>fa-filter</v-icon>
             </v-btn>

            <v-btn v-if="additionConflict" color="primary" dark class="mb-2 mr-3 elevation-1" @click="displayAdditionConflict">
                <v-icon>fa-plus</v-icon>
            </v-btn>
            <template v-else>
              <v-dialog v-if="isAddItemsValid && editableValues && !showAddItemsCustom" max-width="800px" v-model="dialog" :persistent="isFormLoading">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2 mr-3 elevation-0" v-on="on">
                    <v-icon>fa-plus</v-icon>
                  </v-btn>
                </template>
                <slot name="form" :close="close"/>
              </v-dialog>
              <v-btn v-if="showAddItemsCustom" color="primary" dark class="mb-2 mr-3 elevation-1"
                     @click="addItemsCustomEvent" :loading="loadingAddItemsCustom">
                <v-icon>fa-plus</v-icon>
              </v-btn>
            </template>
          </div>
        </div>
      </v-layout>
      <div ref="resizableDiv">
        <v-layout row wrap>
          <v-flex xl12 lg12 md12 sm12 xs12>
            <v-data-table
              dense outlined
              v-model="selected"
              :headers="visibleHeaders"
              fixed-header
              :items="items"
              :loading="loading && softLoading"
              class="mt-0"
              :class="computedClasses"
              :height="tableHeight"
              :ref="datatableRef"
              :options.sync="options"
              :server-items-length="totalItems"
              :show-select="showSelectPanel"
              :single-select="activeSingleSelect"
              :footer-props="{'items-per-page-options': this.getItemsPerPage()}"
              :hide-default-footer="isPaginationHiding"
              @click:row="$event => $emit('click:row', $event)"
              id="table"
           >
              <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
                {{ pageStart }} - {{ pageStop }} {{ of_translated }} {{ totalItemsFormatted }}
              </template>
              <template v-slot:body v-if="loading && !softLoading">
                <v-flex style="width: 95%; margin-top: 1rem;">
                  <v-progress-circular
                    class="px-5 py-5"
                    :style="{ marginLeft: `${tableWidth / 2}px` }"
                    color="primary"
                    indeterminate
                  />
                </v-flex>
              </template>
              <!-- column override -->
              <template v-slot:[slotName]="{item}"
                        v-for="([headerProps, slotName], index) in customColumns">
                <!-- the slot is to let the parent define any cell -->
                <slot :name="slotName" :item="item" :headerProps="headerProps">
                  <CustomCell :value="headerProps.value ? Lget(item, headerProps.value, null): null"
                              v-bind="headerProps"
                              :nonEditableRow="item.editable === false || editableValues === false"
                              :to="headerProps.to? headerProps.to(item): undefined"
                              :style="`color: ${headerProps.textColor}`"
                              :customTextColor="headerProps.textColor"
                              :parent-store-module="storeModule"
                              :selectedItem="item"
                              :ellipsis="headerProps.ellipsize"
                              :ellipsisWidth="headerProps.width"
                              :id="`${index} - ${item.id} - ${headerProps.value}`"
                              :name="slotName"
                              :linkURL="headerProps.urlValue ? Lget(item, headerProps.urlValue.replace('__','.'), null) : null"
                              :numberSeparator="numberSeparator"
                              @save="newValue => saveCellEdition(newValue, headerProps.value, item)"
                  />
                </slot>
              </template>
              <!-- this will render the extra row for totals -->
              <!-- Only rendered if extraFields in the store are not empty, but in the future this could be a exposed slot -->
              <template v-slot:body.append v-if="!isEmpty(extra_fields)">
                <tr class="grey grey lighten-3">
                  <td><strong>Total</strong></td>
                  <td v-for="(header, index) in visibleHeaders" :class="{'text-end': header.align}"
                      :key="`${index}-${header.value}`">
                    <SimpleCell v-if="header.isPercentage" :value="header.total? toPercent(Lget(extra_fields, header.total, '-')) : '-'" v-bind="header"/>
                    <SimpleCell v-else :value="header.total? Lget(extra_fields, header.total, '-') : '-'" v-bind="header"/>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </div>
      <v-layout v-if="showActionsPanel && isActions" class="table-footer-prepend d-flex pl-3 align-center" row wrap>
        <SelectActions
          v-if="customActionsUrl"
          :actions="loadActions"
          :selected="selected"
          :total="totalItems"
          :items="items"
          :itemsCount="itemsCount"
          :allItemsSelected="allSelected"
          :filters="filters"
          :hiddenFilters="hiddenFilters"
          :getActionPath="customActionsUrl"
          v-on:actionApplied="actionApplied($event)"
          v-on:clearSelected="clearSelected"
          v-on:selectOrDeselectAllDataTable="selectOrDeselectAllDataTable"
          @clearSelectAllWithItems="clearSelectAllWithItems"
        />
        <TableActions
          v-else
          :actions="actions"
          :selected="selected"
          :total="totalItems"
          v-on:applyAction="applyAction($event)"
          :editableValues="editableValues"/>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Lget from 'lodash/get'
import Lset from 'lodash/set'
import _isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import zipWith from 'lodash/zipWith'
import debounce from 'lodash/debounce'
import split from 'lodash/split'
import { mapActions } from 'vuex'

import { filtersMixin, unNumberFmt } from '../mixins/filters'
import TableColumnChooser from './TableColumnChooser'
import TableActions from './TableActions'
import CustomCell from './cells/CustomCell'
import SimpleCell from '@/lib/uncrudtable/components/cells/SimpleCell'
import DateRangePickerInField from '@/lib/uncrudtable/components/forms/DateRangePickerInField'
import SelectActions from '@/lib/uncrudtable/components/forms/SelectActions'
import { createNotification } from '@/lib/unnotificationsqueue'
import { BASE_URL, IS_PNG } from '@/variables'

export default {
  name: 'crudTable',
  components: { SimpleCell, TableColumnChooser, TableActions, CustomCell, DateRangePickerInField, SelectActions },
  mixins: [filtersMixin],
  props: {
    parentKey: String,
    parentValue: String,
    storeModule: String,
    headers: {
      type: Array,
      default: () => []
    },
    filterItems: {
      type: Array,
      default: () => []
    },
    customActions: Array,
    customOptions: Object,
    addItems: {
      type: Boolean,
      default: true
    },
    showAddItemsCustom: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Array,
      default: () => [50, 100, 200]
    },
    editableValues: {
      type: Boolean,
      default: true
    },
    parentDataToDelete: Array,
    customActionsUrl: String,
    customTableAdminUrl: String,
    modelDataAdminUrl: String,
    showSelectPanel: {
      type: Boolean,
      default: true
    },
    showActionsPanel: {
      type: Boolean,
      default: true
    },
    showSearchPanel: {
      type: Boolean,
      default: true
    },
    stickyColumns: {
      type: Number,
      default: 0
    },
    hiddenFilters: {},
    isFormLoading: {
      type: Boolean,
      default: false
    },
    additionConflict: {
      type: Object,
      default: null
    },
    passiveOnMount: {
      type: Boolean,
      default: false
    },
    activeSingleSelect: {
      type: Boolean,
      default: false
    },
    fetchEditableOptions: {
      type: Boolean,
      description: 'If true, dispatches "fetchEditableFieldOptions" to get the editable options',
      default: false
    },
    loadingAddItemsCustom: {
      type: Boolean,
      default: false
    },
    softLoading: {
      type: Boolean,
      description: 'When true, the loader is the in-table loader instead of the giant spinner which hides the data',
      default: false
    },
    isElevated: {
      type: Boolean,
      default: true
    },
    title: String,
    footerHeight: {
      type: Number,
      description: 'Extra space to designate under v-data-table',
      default: 0
    },
    adaptableHeight: {
      type: Boolean,
      description: 'Table fills the remaining screen height',
      default: false
    },
    hidePagination: {
      type: Boolean,
      description: 'Param to hide Pagination Footer',
      default: null
    },
    customHeight: {
      type: Number,
      default: 0
    },
    maxHeight: {
      type: Number,
      default: 0
    },
    numberSeparator: {
      type: String,
      default: 'commadot'
    }
  },
  data () {
    return {
      hiddenColsKeyStorage: `${this.storeModule}HiddenColsKey`,
      columnChooserDialog: null,
      options: { ...this.customOptions },
      pagination: {
        sortBy: 'name'
      },
      selected: [],
      search: '',
      dialog: false,
      editedIndex: -1,
      parentItem: null,
      editedItem: {},
      headersVisibleStore: {},
      filters: {},
      dates: {},
      actions: [
        {
          name: 'delete',
          text: this.$t('customActions.deleteSelected'),
          icon: 'fa-trash',
          action: this.deleteSelected,
          defaultAction: true,
          always: false
        }
      ],
      allSelected: false,
      currentAction: '',
      /** Since we could have many CrudTables at the same time,
         * we wan the debounced function to be created for each CrudTable , so fetchFunction is defined in data
         */
      fetchFunction: debounce(() => this.getList(this.options), 200),
      tableHeight: 0,
      tableWidth: 0,
      compareUtilFilter: [],
      isFirstLoad: true,
      recalculateStickyClasses: false
    }
  },
  computed: {
    isPaginationHiding () {
      if (this.hidePagination) {
        return true
      } else {
        return !(this.hidePagination === false || (this.isActions && this.isActions.length > 0) || this.totalItems > 50)
      }
    },

    tableAdminURL () {
      return this.customTableAdminUrl ? BASE_URL + this.customTableAdminUrl : null
    },

    tableModelDataAdminURL () {
      return this.customTableAdminUrl ? BASE_URL + this.modelDataAdminUrl : null
    },

    /** Return customizable cols (the ones with some 'value') **/
    computedClasses () {
      return this.isElevatedClass
    },

    /** Enable/disable 'action panel' if there are valid actions **/
    isActions () {
      return this.loadActions
    },

    isAddItemsValid () {
      if (!this.showAddItemsCustom) {
        return false
      } else {
        return this.addItems
      }
    },

    isElevatedClass () {
      return `elevation-${this.isElevated ? 1 : 0}`
    },
    customColumns () {
      if (this.headers.length > 0) {
        this.headers.forEach(x => {
          x['id'] = this.headers.findIndex(a => a.value === x.value) + 1
          x['sortable'] = x.orderBy
          x['width'] = x.width || 1
        })
      }
      return this.headers.filter(header => header.value).map(header => [header, 'item.' + header.value])
    },
    /** Items number to show (paginate parameter) **/
    items () {
      return this.$store.state[this.storeModule].items
    },
    /** Total items (paginate parameter) **/
    totalItems () {
      return this.$store.state[this.storeModule].count
    },
    of_translated () {
      return this.$t('uncrudtable.of')
    },
    totalItemsFormatted () {
      return unNumberFmt(this.totalItems, 0)
    },
    loading () {
      return this.$store.state[this.storeModule].loading
    },
    /** Total items (paginate parameter) **/
    extra_fields () {
      return this.$store.state[this.storeModule].extra_fields
    },
    /** Filter of hide headers **/
    visibleHeaders () {
      return this.headers ? this.headers.filter(h => Lget(this.headersVisibleStore, h.value, !h.hide)) : []
    },
    loadActions () {
      return this.customActions
    },
    itemsCount () {
      return this.items.length
    },
    isAllItemsSelected () {
      return this.totalItems === this.selected.length
    },
    selectedFilters: {
      get () {
        return this.filters
      },
      set (filters) {
        this.filters = filters
      }
    },
    datatableRef () {
      return `${this.storeModule}Datatable`
    },
    datatableHtmlElement () {
      return this.$refs[this.datatableRef] && this.$refs[this.datatableRef].$el
    }
  },
  watch: {
    options: {
      deep: true,
      async handler (newValue, oldValue) {
        const isEqual = this.optionsFilterIsEqual(newValue, oldValue)
        if (!isEqual) await this.getWithOptions()
      }
    },
    selected: {
      deep: true,
      handler () {
        this.emitModel()
      }
    },
    filterItems: {
      deep: true,
      handler () {
        this.options.filterFields = this.options.filterFields || {}
        for (const filter of this.filterItems) {
          if (!(filter.field in this.options.filterFields)) {
            this.options.filterFields[filter.field] = []
          }
        }
        this.setFilterInitialValues()
      }
    },
    'items.length': {
      handler (newVal, oldVal) {
        if (newVal > oldVal) { this.recalculateStickyClasses = true }
      }
    }
  },
  created () {
    this.options.filterFields = this.options.filterFields || {}
    this.customActionsInjections()
  },
  mounted () {
    if (!this.passiveOnMount) this.getWithOptions()
    this.updateVisibleHeaders()
  },
  updated () {
    this.$nextTick(() => {
      if (this.recalculateStickyClasses) {
        this.computeStickyClasses()
      }
    })
  },
  methods: {
    setFilterInitialValues () {
      if (!this.isFirstLoad) {
        return
      }
      for (const filter of this.filterItems) {
        if (filter.initialValues && filter.initialValues.length) {
          if (filter.type !== 'date') {
            this.options.filterFields[filter.field] = filter.initialValues
          }
        }
      }
      this.isFirstLoad = false
    },
    getSelectAllIcon (filter) {
      const cached_filters = this.options.filterFields[filter.field] || []
      if (cached_filters.length === filter.items.length) {
        return 'mdi-close-box'
      } else if (cached_filters.length > 0) {
        return 'mdi-minus-box'
      } else {
        return 'mdi-checkbox-blank-outline'
      }
    },
    async launchFilters () {
      if (this.options.filterFields) {
        if (!_isEqual(this.compareUtilFilter, this.options.filterFields)) {
          await this.getWithOptions()
          this.compareUtilFilter = JSON.parse(JSON.stringify(this.options.filterFields))
        }
      }
    },
    getFilterValues (filterName) {
      if (this.options.filterFields && filterName in this.options.filterFields) {
        return this.options.filterFields[filterName]
      }
      return []
    },
    filterSelectAllClick (filter) {
      if (this.options.filterFields[filter.field].length === filter.items.length) {
        this.options = { ...this.options, filterFields: { ...this.options.filterFields, [filter.field]: [] } }
      } else {
        this.options = { ...this.options, filterFields: { ...this.options.filterFields, [filter.field]: filter.items.map(item => item.key) } }
      }
    },
    deleteSingleFilter (field, filter) {
      const idx = this.options.filterFields[field].findIndex(item => item === filter)
      if (idx !== -1) {
        this.options.filterFields[field].splice(idx, 1)
      }
    },
    optionsFilterIsEqual (optionCurrent, optionPrev) {
      if (optionCurrent.filterFields) {
        const filterFieldsBackUp = optionCurrent.filterFields
        delete optionCurrent.filterFields
        delete optionPrev.filterFields
        const isEqual = _isEqual(optionCurrent, optionPrev)
        optionCurrent.filterFields = filterFieldsBackUp
        return isEqual
      } else {
        return _isEqual(optionCurrent, optionPrev)
      }
    },

    getItemsPerPage () {
      if (this.itemsPerPage.length) {
        return this.itemsPerPage
      }
      return this.getDefaultItemsPerPage()
    },

    getDefaultItemsPerPage () {
      return [50, 100, 200]
    },

    displayAdditionConflict () {
      this.addNotification(createNotification(this.additionConflict.message, this.additionConflict.status))
    },

    capitalizeStr: function (value) {
      let isPng = IS_PNG ? value.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : value
      return value !== null ? isPng : null
    },

    Lget: Lget,
    Lset: Lset,
    isEmpty: isEmpty,
    ...mapActions({ addNotification: 'addNotification', dismissNotifications: 'dismissNotifications' }),

    /** transform the vuetify page options in django pagination / etc params **/
    vuetifyParamsToDjango ({ page, itemsPerPage, sortBy, sortDesc }) {
      let ordering
      if (sortBy) {
        ordering = zipWith(sortBy, sortDesc, (field, desc) => {
          field = split(field, '.').join('__')
          return desc ? `-${field}` : field
        }).join(',')
      }
      return {
        limit: itemsPerPage,
        offset: page && itemsPerPage ? (page - 1) * itemsPerPage : undefined,
        ordering: ordering
      }
    },

    /** Emit cell edition save **/
    saveCellEdition (newValue, header, item) {
      this.$emit('save', { value: newValue, header: header, item: item })
    },

    /** Execute get request with filter parameters added **/
    selectFilter (value, field, type = null) {
      if (type === 'date' && value[0] === ',') value = null
      if (!this.options.filterFields) {
        this.options = { ...this.options, filterFields: { } }
      }
      this.options.filterFields[field] = value
    },

    getFiltersFromFilterFields (filterFields) {
      let params = {}
      let filters = {}
      if (filterFields) {
        for (let [filterName, filterValues] of Object.entries(filterFields)) {
          if (filterValues.length) {
            params[filterName] = filterValues
            filters[filterName] = filterValues
          }
        }
      }
      if (this.search) {
        params = { ...params, search: this.search }
        filters = { ...filters, search: this.search }
      }

      return { params, filters }
    },

    /** Execute get request with current parameters **/
    async getList ({ page, itemsPerPage, sortBy, sortDesc, filterFields }) {
      try {
        this.$store.commit(`${this.storeModule}/setLoading`, true)
        let filtersFromFilterFields = this.getFiltersFromFilterFields(filterFields)
        let params = filtersFromFilterFields['params']
        let filters = filtersFromFilterFields['filters']

        if (this.parentKey && this.parentValue) {
          params[this.parentKey] = this.parentValue
        }
        params = { ...params, ...this.vuetifyParamsToDjango({ page, itemsPerPage, sortBy, sortDesc }) }

        if (this.isFirstLoad) {
          params['isFirstLoad'] = true
        } else {
          params['isFirstLoad'] = false
        }
        this.selectedFilters = filters
        await this.$store.dispatch(`${this.storeModule}/getItemList`, params)
        if (this.fetchEditableOptions) {
          await this.$store.dispatch(`${this.storeModule}/fetchEditableFieldOptions`)
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.onResize()
      }
    },

    /** Method to fill the table calling to the corresponding store action
     *   Note the use of 'debounce' to avoid repeating ajax calls
     *   Since we could have many CrudTables at the same time, we want
     *   the debounced function to be created for each CrudTable , so fetchFunction is defined in data
     * */
    async getWithOptions () {
      await this.fetchFunction()
      this.arrowSortIconHeaderBehaviour()
    },

    arrowSortIconHeaderBehaviour () {
      if (this.options && this.options.sortBy && this.options.sortBy.length && this.options.sortDesc && this.options.sortDesc.length && this.options.sortDesc[0]) {
        const icon = document.querySelector('#table > div.v-data-table__wrapper > table > thead > tr > th.sortable.active.desc > i')
        if (icon) {
          icon.classList.remove('fa-sort-up')
          icon.classList.add('fa-sort-down')
        }
      } else {
        const icons = document.querySelectorAll('#table > div.v-data-table__wrapper > table > thead > tr > th.sortable > i')
        if (icons.length) {
          for (let icon of icons) {
            icon.classList.remove('fa-sort-down')
            icon.classList.add('fa-sort-up')
          }
        }
      }
    },

    getWithOptionsAndReload () {
      this.options.page = 1
      this.getWithOptions()
    },

    searchDebounce: debounce(function () {
      this.getWithOptionsAndReload()
    }, 1200),

    /** calls the action sending selectedItems and reload if is custom action **/
    applyAction (actionItem) {
      if (this.selected.length > 0) {
        if (!actionItem.defaultAction) {
          actionItem.action({ items: this.selected })
        } else {
          actionItem.action()
        }
        this.selected = []
      }
    },

    actionApplied (messageResponse) {
      if (messageResponse.action_name === 'delete') {
        this.$emit('deleteItemsEvent')
      }
      this.selected = []
      if (!messageResponse.avoid_reload) {
        this.getWithOptions()
      }
      this.messageEventResult(messageResponse)
    },

    messageEventResult (action) {
      if (action) {
        this.addNotification(createNotification(action.messages, action.status))
      }
    },

    addItemsCustomEvent () {
      this.$emit('addItemsEvent')
    },

    /** Delete selected items, execute get request reloading table and reset selected data **/
    deleteSelected () {
      this.deleteItems(this.selected)
      this.getWithOptions()
    },

    deleteItems (items) {
      confirm('¿Seguro que quieres borrar los seleccionados?') &&
        this.$store
          .dispatch(`${this.storeModule}/deleteItems`, items)
          .then(r => {
            this.$emit('item-deleted')
          })
          .catch((error) => {
            console.log(error)
          })
    },

    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    createItems (items) {
      confirm('¿Seguro que quieres duplicar los elementos seleccionados?') &&
        this.$store
          .dispatch(`${this.storeModule}/postManyItems`, items).catch((error) => {
            console.log(error)
          })
      this.selected = []
    },

    close () {
      this.dialog = false
      this.editedIndex = -1
    },

    /**
     *  Set object content like json in local storage with key keyStorage
     **/
    setDataInLocalStorage (keyStorage, content) {
      let stringData = localStorage.getItem(keyStorage)
      let jsonData = JSON.parse(stringData)
      if (jsonData !== content) {
        jsonData = content
        localStorage.setItem(keyStorage, JSON.stringify(jsonData))
      }
    },

    /**
     *  Get json content from local storage with key keyStorage
     **/
    getDataFromLocalStorage (keyStorage) {
      let stringData = localStorage.getItem(keyStorage)
      return JSON.parse(stringData)
    },

    /**
     *  Update headers data with information from localstorage
     **/
    updateVisibleHeaders () {
      const loadedHeaders = this.getDataFromLocalStorage(this.hiddenColsKeyStorage)
      if (loadedHeaders !== null) {
        this.headersVisibleStore = loadedHeaders
      }
    },

    /**
     * Set filtered fields of the headers data to localStorage
     */
    toggleVisibleCol (colValue) {
      const defaultValue = this.headers.filter(h => h.value === colValue)
      const oldValue = Lget(this.headersVisibleStore, colValue, !defaultValue[0].hide)
      Lset(this.headersVisibleStore, colValue, !oldValue)
      // Force the notification of the change value of the headersVisibleStore because changed all object.
      this.headersVisibleStore = Object.assign({}, this.headersVisibleStore)
      this.setDataInLocalStorage(this.hiddenColsKeyStorage, this.headersVisibleStore)
    },

    getComputedByName (name) {
      return Lget(this, name, undefined)
    },
    toPercent (value) {
      return value * 100
    },

    customActionsInjections () {
      if (this.customActions !== undefined) {
        this.customActions.forEach(ca => {
          this.actions = this.actions.filter(a => {
            return a.name !== ca.name
          })
        })
        this.actions = this.actions.concat(this.customActions)
      }
    },

    selectOrDeselectAllDataTable () {
      if (this.allSelected) {
        this.selected = []
        this.allSelected = false
      } else {
        if (this.isAllItemsSelected) {
          this.selected = []
          this.allSelected = false
        } else {
          this.selected = this.items
          this.allSelected = true
          this.selectedFilters = this.getFiltersFromFilterFields(this.options['filterFields'])['filters']
        }
      }
    },

    clearSelected () {
      this.allSelected = false
      this.selected = []
    },

    clearSelectAllWithItems () {
      this.allSelected = false
      this.selected = this.items
    },

    /**
     * This method emits table selected values
     */
    emitModel () {
      this.$emit('modelEmitter', this.selected)
    },

    receiveModel (values) {
      this.selected = values
    },

    onResize () {
      const footerHeight = 59
      const footerMarginBottom = 12
      const minHeight = 150

      const tableFooterHeight = this.isPaginationHiding ? 0 : footerHeight
      const customFooter = this.footerHeight // custom height designate by prop
      if (this.$refs[this.datatableRef]) this.tableWidth = this.$refs[this.datatableRef].$el.querySelector('.v-data-table__wrapper').clientWidth
      if (this.customHeight) {
        this.tableHeight = this.customHeight
        this.$refs[this.datatableRef].$el.querySelector('.v-data-table__wrapper').classList.remove('no-scroll')
      } else if (this.adaptableHeight) {
        const calculatedHeight = window.innerHeight - this.$refs.resizableDiv?.getBoundingClientRect().y - tableFooterHeight - (customFooter > 0 ? customFooter : footerMarginBottom)
        this.tableHeight = calculatedHeight < minHeight ? minHeight : calculatedHeight
        this.$refs[this.datatableRef].$el.querySelector('.v-data-table__wrapper').classList.remove('no-scroll')
      } else {
        this.tableHeight = 'auto'
        if (this.maxHeight) {
          const autoTableHeight = this.$refs[this.datatableRef].$el.getBoundingClientRect().height
          if (autoTableHeight > this.maxHeight) {
            this.tableHeight = this.maxHeight
            this.$refs[this.datatableRef].$el.querySelector('.v-data-table__wrapper').classList.remove('no-scroll')
          } else {
            this.$refs[this.datatableRef].$el.querySelector('.v-data-table__wrapper').classList.add('no-scroll')
          }
        } else {
          this.$refs[this.datatableRef].$el.querySelector('.v-data-table__wrapper').classList.add('no-scroll')
        }
      }
      this.computeStickyClasses()
    },

    computeStickyClasses () {
      if (!this.stickyColumns) return
      const tableRows = this.datatableHtmlElement.getElementsByTagName('table')[0].rows
      if (tableRows.length < 2) return
      const totalStickyCols = this.stickyColumns + (this.showSelectPanel ? 1 : 0)
      for (let rowIdx = 0; rowIdx < tableRows.length; rowIdx++) {
        let stickingOffset = 0
        for (let i = 0; i < totalStickyCols; i++) {
          const firstStickyColumn = i === 0
          const lastStickyColumn = i === totalStickyCols - 1
          const columnHeader = rowIdx === 0
          stickingOffset += firstStickyColumn ? 0 : tableRows[rowIdx].cells[i - 1].getBoundingClientRect().width
          if (tableRows[rowIdx] && tableRows[rowIdx].cells[i]) {
            tableRows[rowIdx].cells[i].style.backgroundColor = 'white'
            tableRows[rowIdx].cells[i].style.position = 'sticky'
            tableRows[rowIdx].cells[i].style.left = `${stickingOffset}px`
            tableRows[rowIdx].cells[i].style.top = `${columnHeader ? 0 : ''}px`
            tableRows[rowIdx].cells[i].style.zIndex = columnHeader ? 4 : 3
            tableRows[rowIdx].cells[i].style.borderRight = `${lastStickyColumn ? '2px solid #ddd' : ''}`
          }
        }
      }
      this.recalculateStickyClasses = false
    }
  }
}
</script>

<style lang="scss">

.CrudTable {
  &:focus-visible {
      outline: none;
  }

  tr > td > i.v-icon{
    display: inline !important;
  }

}
  .table-footer-prepend {
    margin-top: -58px;
    height: 58px;
  }

  // override the default inline-flex to avoid sort icon in new line
  .v-icon.v-icon.v-data-table-header__icon {
   display: inline;
  }

  #inspire > div > main > div > div {
    padding-bottom: 8px;
    padding-top: 12px;
  }

  #inspire > div > main > div > div > div > div {
    padding-bottom: 0;
    padding-top: 0;
  }

  .no-scroll {
    overflow-y: hidden !important;
  }
</style>
