<template>
  <div class="align-right" style="width: 210px; float:right;">
    <DateRangePickerInField label="fecha" :start.sync="start" :end.sync="end" @save="val => $emit('save', returnValue)"/>
  </div>
</template>

<script>
/**
   * This component is used to create a selector inside a cell, you must pass some parameters in order to work properly.
   * @itemsToChoose This is the items that will be show in the selector. This items must have external id and name.
   * @label_name The name to show as default in the selector.
   *
   */
import DateRangePickerInField from '../forms/DateRangePickerInField'

export default {
  name: 'DateRangePickerCell',
  components: { DateRangePickerInField },
  props: {
    value: [String, Number, Boolean]
  },
  data () {
    return {
      start: this.value ? this.value.split(' - ')[0] : '',
      end: this.value ? this.value.split(' - ')[0] : ''
    }
  },
  computed: {
    returnValue () {
      return [this.start, this.end].join(' - ')
    }
  }
}
</script>

<style scoped>

</style>
