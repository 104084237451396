import Vue from 'vue'
import Component from 'vue-class-component'
import KpiCard from '@/apps/core/components/KpiCard'
import { unNumberFmt } from '../../../uncrudtable/mixins/filters'

@Component({
  components: { KpiCard },
  props: {
    kpis: {
      type: Array,
      default: []
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    }
  }
})

class CustomKpisBlock extends Vue {
  getKpiColor (kpiValue, isInverted) {
    let color = 'black'
    if (typeof kpiValue === 'number') {
      if (!isInverted) {
        if (kpiValue < 0) {
          color = '#FF5252'
        } else {
          color = '#66BB6A'
        }
      } else {
        if (kpiValue < 0) {
          color = '#66BB6A'
        } else {
          color = '#FF5252'
        }
      }
    }
    return color
  }

  getValueColor (value, coloring) {
    return coloring !== 0 && value !== '-' ? this.getKpiColor(parseFloat(value), coloring === 2) : 'black'
  }

  getKpiValue (kpi) {
    // eslint-disable-next-line no-undef
    let kpiValue = _.get(this.value, kpi.value)
    return kpiValue === undefined || kpiValue === null ? '-' : unNumberFmt(kpiValue, Number.isInteger(kpiValue) ? 0 : 2)
  }
}

export default CustomKpisBlock
