<template>
  <v-edit-dialog
    large
    persistent
    @save="save"
    cancel-text="Cancelar"
    save-text="Aceptar"
  >
    <div class="primary--text text--darken-2">{{ displayValue }}</div>
    <template v-slot:activator>
      <div class="mt-4 title">Actualizar {{name}}</div>
    </template>
    <template v-slot:input>
      <div class="mt-4 title">Actualizar {{name}}</div>
    </template>
    <template v-slot:input>
      <v-autocomplete prepend-icon="fa-tag"
                      :items="items"
                      :loading="loading"
                      hide-no-data
                      :search-input.sync="query"
                      no-filter
                      :item-text="itemText"
                      v-model="internalValue"
                      return-object
      >
      </v-autocomplete>
    </template>
  </v-edit-dialog>
</template>

<script>
import { filtersMixin } from '../../mixins/filters'
import debounce from 'lodash/debounce'

export default {
  name: 'AutoCompleteCell',
  mixins: [filtersMixin],
  props: {
    value: [String, Number, Boolean, Object],
    name: String,
    modelToUse: String,
    itemText: String
  },
  data () {
    return {
      query: '',
      loading: false,
      editingErrors: {},
      internalValue: this.value
    }
  },
  watch: {
    query () {
      // Items have already been requested or query is empty
      if (!(this.value) && this.loading === false && (this.query === null || this.query === '')) {
        // pass
      } else {
        this.loading = true
        this.getItems(this.query)
      }
    }
  },
  computed: {
    items: function () {
      if (this.modelToUse) {
        return this.$store.state[this.modelToUse].items
      }
      return null
    },
    displayValue: function () {
      return this.value ? this.value[this.itemText] || ' - ' : ' - '
    }
  },
  methods: {
    getItems: debounce(function (searchValue) {
      this.$store
        .dispatch(`${this.modelToUse}/getItemList`, { search: searchValue, limit: 10, ordering: this.itemText })
        .then(() => { this.loading = false })
    }, 500),
    save: function () {
      this.$emit('save', this.internalValue)
    }
  }
}
</script>

<style scoped>

</style>
