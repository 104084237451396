import i18n from '@/i18n'
import { isEmpty, isUndefined, isEqual } from 'lodash'
import apiClient from '@/lib/unlogin/store/apiclient'
import { createNotification, createWarningNotification } from '@/lib/unnotificationsqueue'
import { RESPONSE_LEVEL } from '@/variables'
import { parseFormattedUrl } from '@/apps/core/helpers/utils'
import { requestsMonitorsMixin } from '@/apps/core/mixins/requests_monitors'
import HierarchiesFiltersList from '../HierarchiesFiltersList/HierarchiesFiltersList.vue'
import HierarchiesBreadcrumb from '../HierarchiesBreadcrumb/HierarchiesBreadcrumb.vue'

export default {
  name: 'HierarchiesFiltersSelector',
  components: { HierarchiesFiltersList, HierarchiesBreadcrumb },
  mixins: [ requestsMonitorsMixin ],
  props: {
    entity: {
      type: String,
      description: 'Label of entity on which hierarchy tree is based (i.e. Clientes, Productos)',
      required: true
    },
    endpoints: {
      type: Object,
      description: 'Endpoints object for specific hierarchy API calls',
      required: true
    },
    selectableLevels: {
      type: Object,
      description: 'Defines which hierarchyElements are selectable depending on their level.' +
        'If prop is undefined, every level is selectable (empty array is set as default) (?)' +
        '{ "hierarchyExternalId": { min: level.depth, max: level.depth }, ... }',
      default: () => {}
    },
    value: {
      type: Object,
      description: 'Value prop from UnCustomUI, references item to be in sync with CustomBlocks (i.e. currentItem)',
      required: true
    },
    hierarchyFiltersEndpoint: {
      type: String,
      description: 'From Wrapper: Returns \'hierarchyFilters\' endpoint formatted with the current entity id (i.e. Campaign, Promo)',
      required: true
    },
    customStoreModule: {
      type: String,
      default: 'hierarchiesFilterSelectorStore'
    },
    doNotCommitHierarchyFilters: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      treeDataTop: [],
      treeDataBottom: [],
      hierarchyList: [],
      levelList: [],
      selectedNodes: [],
      selectedNodesToDelete: [],
      childrenInMemory: [],

      hierarchySelected: null,
      levelSelected: null,
      isLoadingTopTree: true,
      isLoadingBottomTree: false,
      isLoadingHierarchies: true,
      isLoadingSaveButton: false,
      topSearchValue: '',
      bottomSearchValue: '',

      propertyNames: { text: 'name' },
      dropdownExclusionOptions: [],

      secondTableInitItems: null
    }
  },
  created () {
    this.dropdownExclusionOptions = this.setDropdownExclusionOptionsValues()
  },

  async mounted () {
    try {
      await this.checkEndpointProps()
      await this.fetchHierarchyList()
      // 1.- Requires correct implementation of level filtering:
      // this.$watch('topSearchValue', debounce(this.fetchTreeDataTop, 500))
      await this.fetchTreeDataTop()
      this.parseTreeDataBottom(this.hierarchiesFilters)
    } catch (err) {
      let alert = (err.message && err.level) ? err : { message: this.$t('general.noResults'), level: RESPONSE_LEVEL.ERROR }
      await this.$store.dispatch('addNotification', createNotification(alert.message, alert.level))
    } finally {
      this.isLoadingHierarchies = false
      this.isLoadingTopTree = false
      this.isLoadingBottomTree = false
      if (this.secondTableInitItems === null) this.secondTableInitItems = [...this.treeDataBottom]
    }
  },
  computed: {

    isTotalCount0 () {
      return this.$store.state[`hierarchiesFiltersList${this.entity}`].totalCount === 0
    },

    treeOptionsTop () {
      return {
        autoCheckChildren: false,
        parentSelect: true,
        minFetchDelay: true,
        propertyNames: this.propertyNames,
        fetchData: this.deepFetch
      }
    },
    treeOptionsBottom () {
      return {
        propertyNames: this.propertyNames,
        filter: {
          matcher (query, node) {
            return new RegExp(query, 'i').test(node.data.textToSearch)
          },
          emptyText: i18n.tc('general.noResults')
        }
      }
    },
    hierarchiesFilters () {
      return this.$store.getters[`${this.customStoreModule}/getFilters`]
    },
    breakHeight () {
      return this.$vuetify.breakpoint.height
    },
    /**
   * Return the total elements of the first table.
   * @returns {number}
   */
    totalFirstTableElementsCount () {
      return this.selectedNodes.length
    },
    /**
   * Return the total elements of the second table.
   * @returns {number}
   */
    totalSecondTableElementsCount () {
      return this.treeDataBottom.length
    },
    /**
   * Return the selected elements (for removal) of the second table.
   * @returns {number}
   */
    totalSecondTableSelectedElementsCount () {
      return this.selectedNodesToDelete.length
    },
    /**
   * Returns true if elements from every HierarchyLevel are selectable
   * (If selectableLevels prop is not defined: {} - or if no selectableLevels with min/max are defined, only with default)
   */
    isEveryLevelSelectable () {
      return isEmpty(this.selectableLevels) || Object.keys(this.selectableLevels).every(hierarchy => !this.selectableLevels[hierarchy].min && !this.selectableLevels[hierarchy].max)
    },
    selectAll () {
      return this.totalSecondTableSelectedElementsCount === this.totalSecondTableElementsCount
        ? this.$t('general.cleanSelection')
        : this.$t('general.selectAll')
    }
  },
  watch: {
    selectedNodes () {
      this.sendChanges()
    },
    selectedNodesToDelete () {
      this.sendChanges()
    },
    treeDataBottom () {
      this.sendChanges()
    },
    hierarchySelected (newValue) {
      this.setLevelList(newValue)
    },
    async levelSelected (newValue) {
      await this.fetchTreeDataTop(newValue)
    }
  },
  methods: {
    /**
   * Checks that all endpoints required in the component are passed as CustomBlock's props, and alerts user otherwise
   */
    checkEndpointProps () {
      if (!this.doNotCommitHierarchyFilters) {
        let requiredEndpoints = ['hierarchiesList', 'hierarchyElement', 'hierarchyFilters', 'hierarchicalElement']
        requiredEndpoints.forEach(endpoint => {
          if (!Object.keys(this.endpoints).includes(endpoint)) {
            let error = { message: this.$tc('general.noApiConfig'), level: RESPONSE_LEVEL.ERROR }
            throw error
          }
        })
      }
    },
    /**
   * Called at mount: Fetches all hierarchies from API and sets first value as default
   */
    async fetchHierarchyList () {
      this.isLoadingHierarchies = true
      let response = await apiClient.get(this.endpoints.hierarchiesList)
      this.hierarchyList = response.data.results.filter(hierarchy => this.isEveryLevelSelectable || hierarchy.external_id in this.selectableLevels)
      if (this.selectableLevels && !isEmpty(this.selectableLevels)) {
        this.hierarchyList.sort((a, b) => {
          let selectableA = this.selectableLevels[a.external_id]
          let selectableB = this.selectableLevels[b.external_id]
          return selectableA && selectableA.default ? -1 : selectableB && selectableB.default ? 1 : 0
        })
      }
      if (!this.hierarchyList.length) {
        let error = { message: this.$t('general.noResults'), level: RESPONSE_LEVEL.WARNING }
        throw error
      }
      this.hierarchySelected = this.hierarchyList[0]
      this.setLevelList(this.hierarchySelected)
    },
    /**
   * Sets level dropdown list according to the selected hierarchy
   * @param hierarchy {Object} hierarchy selected
   */
    setLevelList (hierarchy) {
      this.levelList = hierarchy.levels
      this.levelSelected = this.levelList[0]
    },
    /**
   * Fetches hierarchy tree data for both initial dialog show (searchQuery = false) and when searching (searchQuery = true)
   */
    async fetchTreeDataTop (options = {}) {
      if (options.clearSearch) {
        this.topSearchValue = ''
      }
      try {
        this.isLoadingTopTree = true
        // 1.- Requires correct implementation of level filtering:
        const searchQuery = this.topSearchValue ? `?q=${this.topSearchValue}` : ''
        this.childrenInMemory = []
        const parsedElementEndpoint = parseFormattedUrl(this.endpoints.hierarchyElement, { levelId: this.levelSelected.id })
        const response = await this.onMonitorRequest(apiClient.get(`${parsedElementEndpoint}${searchQuery}`))
        this.treeDataTop = this.parseTreeDataTop(response.data)
        if (searchQuery) {
          this.parseChildrenInMemory(this.treeDataTop)
        }
      } catch (error) {
        this.treeDataTop = []
        await this.$store.dispatch('addNotification',
          createNotification(this.$t('general.searchError'), RESPONSE_LEVEL.ERROR))
      } finally {
        if (!this.isPendingRequests) {
          this.isLoadingTopTree = false
        }
      }
    },
    /**
   * Defines nodes with children already fetched to manage API calls
   * @param subtree
   */
    parseChildrenInMemory (subtree) {
      this.childrenInMemory = [...this.childrenInMemory, ...subtree.filter(element => element.children)]
      subtree.forEach(element => {
        if (element.children) {
          this.childrenInMemory = [...this.childrenInMemory, ...element.children.filter(child => child.children)]
          this.parseChildrenInMemory(element.children)
        }
      })
    },
    /**
   * Performs node's children fetch when its 'open' arrow is clicked (bound to treeOptionsTop.fetchData)
   * NOTE: so far, the "depth" variable has been alternating along varying specifications. It is yet to be parameterized
   * @param node {Object} clicked node element whose children are to be searched
   */
    async deepFetch (node) {
      const childInMemory = this.childrenInMemory.find(fetchedChild => fetchedChild.id === node.id)
      if (!childInMemory) {
        const depth = 1
        const parsedElementEndpoint = parseFormattedUrl(this.endpoints.hierarchyElement, { levelId: this.levelSelected.id })
        const res = await apiClient.get(`${parsedElementEndpoint}${node.id}/?depth=${depth}`)

        if (depth > 1) {
          this.childrenInMemory = [...this.childrenInMemory, ...res.data.children]
        }
        return this.parseTreeDataTop(res.data.children)
      }
    },
    /**
   * Adds 'isBatch' and 'data.level' attributes accordingly for correct LiquorTree implementation
   * @param subtree - The subtree fetched in the last request
   */
    parseTreeDataTop (subtree) {
      return subtree.map(element => {
        if (element.children) {
          element.children = this.parseTreeDataTop(element.children)
        }
        // Add element.name to tooltip
        element.hierarchy_level.name = `${element.hierarchy_level.name}: ${element.name}`
        element.state = element.children ? { expanded: true } : { expanded: false }
        return { ...element, isBatch: element.has_children, data: { level: element.hierarchy_level }, state: element.state }
      })
    },
    /**
   * Fetched treeDataBottom parsing for correct LiquorTree implementation
   * @param array: existing values from API to parse into treeDataBottom
   */
    parseTreeDataBottom (array) {
      this.treeDataBottom = array.map(element => {
        return {
          id: element.hierarchy_element,
          data: { 'textToSearch': element.breadcrumb.join('/'), 'breadcrumb': element.breadcrumb, 'included': element.included }
        }
      })
      this.setTreeDataBottom()
    },
    /**
   * Called by each treeNode tag when rendering in order to add CSS classes according to its state
   * @param node {Object} element from the tree being rendered
   * @param isTop {Boolean} true when rendering top tree element, false when bottom tree element
   * @returns {string} CSS classes to bind to the treeNode tag
   */
    computeNodeStyles (node, { isTop }) {
      let classes = ''
      let selectedNodesList = isTop ? this.selectedNodes : this.selectedNodesToDelete
      if (selectedNodesList.findIndex(selectedNode => selectedNode.id === node.id) > -1) {
        classes += '--selected '
      }
      if (isTop && this.treeDataBottom.findIndex(displayed => displayed.id === node.id) > -1) {
        classes += '--added '
      }
      if (isTop && !this.isEveryLevelSelectable && !this.isElementSelectable(node.data.level.depth)) {
        classes += '--nonSelectable '
      }
      return classes
    },
    /**
   * Compares element's level with the selectableLevels prop, which defines the selectable range (min/max)
   * @param depth {Number} Element's hierarchy level
   * @returns {boolean}
   */
    isElementSelectable (depth) {
      let hierarchy = this.hierarchySelected.external_id
      let { min, max } = this.selectableLevels[hierarchy]
      return (isUndefined(min) && isUndefined(max)) || ((depth <= max) && (depth >= min))
    },
    /**
   * Verify if the selected value exists in the array.
   * @param arr The array to verify.
   * @param selectedItem The element to be compared.
   * @returns {Boolean}
   */
    isInTheArray (arr, selectedItem) {
      return arr.some(data => {
        return selectedItem.id === data.id
      })
    },

    /**
   * Removes selectedItem from array. Does nothing if item doesn't exist in array
   * @param arr The array to verify.
   * @param selectedItem The element to be removed.
   */
    removeNodeFromArray (arr, selectedItem) {
      let index = arr.findIndex(element => element.id === selectedItem.id)
      if (index > -1) {
        arr.splice(index, 1)
      }
    },

    /**
   * Handler when selecting an element from the top table: selects or unselects node
   * @param selectedItem The selected element.
   */
    selectNodeFromTopTree (selectedItem) {
      if (!this.isInTheArray(this.selectedNodes, selectedItem)) {
        if (!this.isInTheArray(this.treeDataBottom, selectedItem)) {
          let selectedItemDepth = selectedItem.data.level.depth
          if (this.isEveryLevelSelectable || this.isElementSelectable(selectedItemDepth)) {
            selectedItem.check()
            this.selectedNodes.push(selectedItem)
          }
        }
      } else {
        selectedItem.uncheck()
        this.removeNodeFromArray(this.selectedNodes, selectedItem)
      }
    },

    /**
   * Handler when selecting an element from the bottom table: selects or unselects node
   * @param selectedItem
   */
    selectNodeFromBottomTree (selectedItem) {
      if (!this.isInTheArray(this.selectedNodesToDelete, selectedItem)) {
        selectedItem.check()
        this.selectedNodesToDelete.push(selectedItem)
      } else {
        selectedItem.uncheck()
        this.removeNodeFromArray(this.selectedNodesToDelete, selectedItem)
      }
    },

    toggleSelectAllNodesFromBottomTree () {
      this.$refs.bottomTree.checked()
      const allItems = this.$refs.bottomTree.tree.model
      if (this.totalSecondTableElementsCount === this.totalSecondTableSelectedElementsCount) {
        this.selectedNodesToDelete = []
      } else {
        this.selectedNodesToDelete = [...allItems]
      }
    },

    /**
   * Change the 'included' state from the selected node.
   * @param node The node to change state.
   * @param event The state value.
   */
    changeIncludedState (node, event) {
      this.$set(node.data, 'included', event)
      let index = this.treeDataBottom.findIndex(element => element.id === node.id)
      this.treeDataBottom[index].data.included = event
    },

    /**
   * Parses and adds the selected values from the top table to display in the bottom table
   */
    addElementsToBottom () {
      let selectedNodes = [...this.selectedNodes]
      selectedNodes.forEach(element => {
        if (!this.isInTheArray(this.treeDataBottom, element)) {
          let { id, states } = element
          let data = { breadcrumb: this.getBreadcrumb(element), textToSearch: element.text, included: true, isBatch: false }
          this.treeDataBottom.unshift({ id, states, data })
          this.removeNodeFromArray(this.selectedNodes, element)
          element.uncheck()
        }
      })
      this.setTreeDataBottom()
    },

    /**
   * Removes selected elements from bottom table
   */
    removeElementsFromBottom () {
      this.selectedNodesToDelete.forEach(nodeToDelete => {
        nodeToDelete.uncheck()
        this.removeNodeFromArray(this.treeDataBottom, nodeToDelete)
      })
      this.selectedNodesToDelete = []
      this.setTreeDataBottom()
    },

    removeSingleElementFromBottom (node) {
      node.uncheck()
      if (!this.isInTheArray(this.selectedNodesToDelete, node)) {
        this.removeNodeFromArray(this.selectedNodesToDelete, node)
      }
      this.removeNodeFromArray(this.treeDataBottom, node)
      this.setTreeDataBottom()
    },

    /**
   * Get the parents of a node.
   * @param node
   * @returns {[*]}
   */
    getBreadcrumb (node) {
      const fullPath = [node.text]
      node.recurseUp(parentEl => fullPath.unshift(parentEl.text))
      fullPath.unshift(this.hierarchySelected.name)
      return fullPath
    },

    /**
   * Generate a array of object with the model structure.
   */
    async saveFilters () {
      // eslint-disable-next-line no-return-await
      if (this.isTotalCount0) return await this.$store.dispatch('addNotification', createWarningNotification(this.$t('general.noItemsSelected')))
      let filtersToSave = []
      if (this.doNotCommitHierarchyFilters) {
        this.treeDataBottom.forEach(element => {
          let filter = {
            breadcrumb: [...element.data.breadcrumb],
            hierarchy_element: element.id,
            included: element.data.included
          }
          filtersToSave.push(filter)
        })
        this.$store.commit(`${this.customStoreModule}/setFilters`, filtersToSave)
      } else {
        this.isLoadingSaveButton = true
        this.treeDataBottom.forEach(element => {
          let filter = {
            'hierarchy_element': element.id,
            'included': element.data.included
          }
          filtersToSave.push(filter)
        })
        await apiClient.post(this.hierarchyFiltersEndpoint, filtersToSave).finally(() => {
          this.isLoadingSaveButton = false
        })
      }
      this.$emit('save-filters')
    },

    /**
   * Set the bottom table model. This is because liquor tree library is not reactive.
   */
    setTreeDataBottom () {
      this.$refs.bottomTree.setModel(this.treeDataBottom)
    },

    setDropdownExclusionOptionsValues () {
      return [{ value: true, text: this.$t('general.include') }, { value: false, text: this.$t('general.exclude') }]
    },

    sendChanges () {
      const changes = {
        firstTable: this.totalFirstTableElementsCount,
        secondTable: this.totalSecondTableElementsCount - this.secondTableInitItems.length,
        areEqual: isEqual(this.secondTableInitItems, this.treeDataBottom) ? 0 : 1,
        secondTableSelected: this.totalSecondTableSelectedElementsCount
      }
      this.$emit('pendingChanges', changes)
    }
  }
}
