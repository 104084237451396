<template>
  <div>
    <v-tooltip v-if="!isFile" color="black" bottom dark :disabled="isTooltipDisabled" class="show-over-items">
      <template v-slot:activator="{ on }">
        <span ref="spanRef" v-on="on" :class="textStyles" @click="openLinkNewTab" :style="ellipsisStyle">{{formatted}}</span>
      </template>
      <span v-html="formattedToolTip" />
    </v-tooltip>

    <v-tooltip v-if="isFile && typeof value === 'string' && value !== '-'" color="black" class="show-over-items" dark left>
      <template v-slot:activator="{ on }">
        <v-btn :href="value" color="primary" target="_blank" fab small dark v-on="on">
          <v-icon>fas fa-file-download</v-icon>
        </v-btn>
      </template>
      <span>{{formattedFileName}}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { unIntegerFmt, unNumberFmt } from '../../mixins/filters'
import { isNull, isUndefined, split } from 'lodash'

export default {
  name: 'SimpleCell',
  props: {
    value: [String, Number, Array, Boolean],
    formatNumber: Boolean,
    decimalsNumber: Number,
    numberSeparator: String,
    type: String,
    nonEditableRow: Boolean,
    isFile: Boolean,
    ellipsis: {
      type: Boolean,
      default: false
    },
    ellipsisWidth: {
      type: Number,
      default: 200
    },
    urlType: String,
    linkURL: String
  },
  data () {
    return {
      isTooltipDisabled: true
    }
  },
  computed: {
    formatted () {
      if (this.idInvalidValue()) {
        if (this.value == null) {
          return '-'
        }
        return this.value
      } else if (this.type === 'integer') {
        // case it is an integer value
        return unIntegerFmt(this.value)
      } else if (this.formatNumber) {
        // Case it is a money number
        return unNumberFmt(this.value, this.decimalsNumber, this.numberSeparator)
      } else if (this.value.name !== undefined) {
        // Case It is a simple object with name
        return this.value.name
      } else if (typeof this.value === 'object') {
        // Case It is a complex object we suppose it is a list with names
        const valuesToShow = []
        for (let value of Object.values(this.value)) {
          valuesToShow.push(value.name ? value.name : value)
        }
        return valuesToShow.join(', ') // Convert it to a string of values separated by commas
      } else {
        return this.value
      }
    },
    formattedToolTip () {
      if (typeof this.formatted === 'string') {
        if (this.formatted.length > 50) return this.formatted.match(/.{1,50}/g).join(`<br />`)
        return this.formatted
      } else {
        return this.formatted
      }
    },
    formattedFileName () {
      return this.getFileName(this.value)
    },
    ellipsisStyle () {
      return this.ellipsis ? `max-width: ${this.ellipsisWidth}px;` : ''
    },
    textStyles () {
      let styles = ''
      if (this.ellipsis) styles += 'd-inline-block text-truncate'
      if (this.urlType !== 'not_url' && this.linkURL) styles += ' is-link'
      return styles
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.calculateEllipsis()
    })
  },
  methods: {
    openLinkNewTab () {
      if (this.linkURL) {
        if (this.urlType === 'internal_url') {
          const link = window.origin + this.linkURL
          window.open(link, '_blank')
        } else if (this.urlType === 'external_url') {
          const link = this.linkURL
          window.open(link, '_blank')
        }
      }
    },
    calculateEllipsis () {
      if (this.ellipsis && this.$refs.spanRef) {
        const textWidth = this.$refs.spanRef.clientWidth
        this.isTooltipDisabled = textWidth < this.ellipsisWidth
      } else {
        this.isTooltipDisabled = true
      }
    },
    idInvalidValue () {
      return isUndefined(this.value) || isNull(this.value)
    },
    getFileName: function (v) {
      return v ? this.serializeFileName(v.split('/').slice(-1)[0]) : ''
    },
    serializeFileName (filename) {
      // Function to eliminate white space encoded characters
      return split(filename, '%20').join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.show-over-items {
  z-index: 8;
}
.is-link {
  color: var(--v-anchor-base);
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
