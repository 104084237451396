import Vue from 'vue'
import Component from 'vue-class-component'
import VJsf from '@koumoul/vjsf/lib/VJsf.js'
import hjson from 'hjson'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import '@koumoul/vjsf/lib/VJsf.css'
import '@koumoul/vjsf/lib/deps/third-party.js'

@Component({
  props: {
    customBlockData: {
      type: Object,
      default () {
        return []
      }
    },
    value: {
      type: Object,
      default () {
        return []
      }
    },
    customComponentCatalog: {
      type: Object,
      default () {
        return []
      }
    },
    options: {
      type: Object,
      default () {
        return []
      }
    },
    viewLoading: Boolean
  },
  components: {
    VJsf
  }
})
class CustomBlock extends Vue {
  get parsedVuetifyJsonschema () {
    return hjson.parse(this.customBlockData.vuetify_jsonschema)
  }

  get extendedOptions () {
    return { ...this.options, locale: this.$vuetify.lang.current }
  }

  get clonedValue () {
    return cloneDeep(this.value)
  }

  /**
   * Return the custom params from the back. In the component you should handle as this.$attrs.bind.
   * @returns {*} Object with the props setted in the admin.
   */
  get unCustomComponentParams () {
    return this.customBlockData.vue_component_parameters
  }

  /**
   * Called when the form model is going to change by user input
   * this method should emit the input event up the component hierarchy
   */
  onInput (value) {
    /* following code will iter all keys in value, detect the keys with changes, and then delete any other dependant keys
    * specified in 'selectorDeps'
    * 'selectorDeps' is an optional custom property in our schemas to include the array of keys that depend on this selector
    */
    const originalKeys = Object.keys(value)
    originalKeys.forEach(key => {
      if (key in this.parsedVuetifyJsonschema.properties) {
        const schema = this.parsedVuetifyJsonschema.properties[key]
        const selectorDeps = schema.selectorDeps
        const prevValue = value[key]
        const nextValue = this.value[key]
        const shouldClearValue = (
          isArray(selectorDeps) &&
          !isEqual(prevValue, nextValue) &&
          // condition to avoid glitches when default value is defined for selectors
          !(isEqual(prevValue, schema['default']) && nextValue === undefined)
        )
        if (shouldClearValue) {
          selectorDeps.forEach(depKey => {
            // must delete default to fully clear value , and avoid 'undefined' in selector
            Vue.delete(this.parsedVuetifyJsonschema.properties[depKey], 'default')
            Vue.delete(value, depKey)
            Vue.delete(this.value, depKey)
          })
        }
      }
    })
    this.$emit('input', { ...value })
  }
}

export default CustomBlock
