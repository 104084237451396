<i18n>
  {
  "en": {
  "acceptLabel": "YES",
  "closeLabel": "NO"
  },
  "es": {
  "acceptLabel": "SI",
  "closeLabel": "NO"
  }
  }
</i18n>

<template>
  <v-dialog
    v-model="dialog"
    :max-width="550"
    @click:outside="close"
    :persistent="persistent"
  >
    <v-card>
      <v-card-title class="headline">{{ titleDialog }}</v-card-title>
      <slot name="confirm-text">
        <v-card-text style="font-size: 1.1rem">{{ textDialog }}</v-card-text>
        <v-card-text class="d-flex justify-center mt-6">
          <v-progress-circular
            v-if="loading"
            :size="70"
            :width="10"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-card-text>
      </slot>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="showButtons" outlined class="font-weight-bold" text @click="denyCondition">
          {{ cancelText || $t('closeLabel') }}
        </v-btn>
        <v-btn v-if="showButtons" :color="confirmColor" :dark="confirmColor === 'red'" class="font-weight-bold" @click="acceptCondition">
          {{ confirmText || $t('acceptLabel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogConfirmation',
  props: {
    titleDialog: {
      type: String,
      required: true
    },
    textDialog: {
      type: String,
      required: true
    },
    value: Boolean,
    confirmText: { type: String },
    confirmColor: { type: String, default: 'primary' },
    cancelText: { type: String },
    persistent: { type: Boolean, default: false },
    cancelOption: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true },
    loading: { type: Boolean, default: false }
  },
  methods: {
    denyCondition () {
      this.dialog = false
      this.$emit('denyCondition')
    },
    acceptCondition () {
      this.dialog = false
      this.$emit('acceptCondition')
    },
    cancel () {
      this.dialog = false
    },
    close () {
      if (!this.persistent) {
        (this.cancelOption) ? this.cancel() : this.denyCondition()
      }
    }
  },
  computed: {
    dialog: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
