<template>
  <div>
    <div class="d-flex align-center justify-start">
      <div class="d-flex height-40">
        <v-select dense outlined :label="$t('customActions.label')" :items="actions" v-model="selectedAction" return-object hide-details
                   class="mr-3 mw-400">
        </v-select>
        <v-btn background-color="white"
               color="primary"
               outlined
               min-height="40"
               :disabled="haveItemsSelect || loadingAction"
               :loading="loadingAction"
               @click="loader = 'loadingAction'">{{$t('general.apply')}}
        </v-btn>
      </div>
      <div class="d-flex align-start cus-pt">
        <p class="caption pl-3 pt-1">{{ totalItemsSelect }}</p>
        <v-btn v-if="itemsCount !== 0"
             class="caption ml-1"
             dense
             text
             small
             color="primary darken-2"
             @click="selectAllItems"> {{ selectAll }}
        </v-btn>
      </div>
    </div>
    <DialogConfirmation :titleDialog="titleDialog" :textDialog="textDialog"
                        v-model="showAlert"
                        v-on:acceptCondition="execAction(selectedAction)"
                        v-on:denyCondition="denyAction()"/>
  </div>
</template>

<script>
import apiClient from '@/lib/unlogin/store/apiclient'
import DialogConfirmation from './DialogConfirmation'
import contentDisposition from 'content-disposition'
import { SELECT_ALL_ID, IS_PNG } from '@/variables'
import { unNumberFmt } from '../../mixins/filters'

const CONTENT_TYPE_EXTENSION_MAP = {
  'text/csv': 'csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
}

export default {
  name: 'SelectActions',
  components: { DialogConfirmation },
  props: {
    actions: Array,
    selected: Array,
    items: Array,
    total: Number,
    getActionPath: {
      type: String,
      required: true
    },
    itemsCount: {
      type: Number,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    parentValue: String,
    hiddenFilters: {},
    allItemsSelected: Boolean
  },
  data () {
    return {
      selectedAction: null,
      titleDialog: '',
      textDialog: '',
      showAlert: false,
      loader: null,
      loadingAction: false
    }
  },
  computed: {
    selectedItemsLength () {
      return this.allItemsSelected ? this.totalFormatted : this.numberOfSelectedItemsFormatted
    },
    totalItemsSelect () {
      return `${this.selectedItemsLength} ${this.$tc('general.elementsSelectedAndTotal', this.totalFormatted)}`
    },
    haveItemsSelect () {
      return this.selected.length === 0 || this.selectedAction == null
    },
    totalFormatted () {
      return unNumberFmt(this.total, 0)
    },
    numberOfSelectedItemsFormatted () {
      return unNumberFmt(this.selected.length, 0)
    },
    selectAll () {
      return this.selectedItemsLength === this.totalFormatted
        ? this.capitalizeObj(this.$t('general.cleanSelection'))
        : this.capitalizeObj(this.$t('general.selectAll'))
    }
  },
  watch: {
    items () {
      this.$emit('clearSelected')
    },
    loader () {
      const l = this.loader
      this[l] = !this[l]

      if (l === 'loadingAction') {
        this.verifyIfRequireConfirmation(this.selectedAction)
      }

      this.loader = null
    },
    selected () {
      if (this.allItemsSelected && this.selected.length < this.itemsCount) {
        this.clearSelectAllWithItems()
      }
    }
  },
  methods: {
    verifyIfRequireConfirmation (action) {
      if (action.confirmation) {
        this.showAlert = true
        this.titleDialog = action.text ? action.text : this.$t('general.warning')
        this.textDialog = this.$t('customActions.question')
      } else {
        this.execAction(action)
      }
    },
    capitalizeObj (value) {
      if (IS_PNG) {
        if (Array.isArray(value)) {
          value.forEach(x => {
            x.text = x.text.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
          })
        } else if (typeof value === 'string') {
          value = value.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
        }
      }
      return value
    },
    execAction (action) {
      const content = {
        'action_name': action.action
      }
      const listIds = this.allItemsSelected ? SELECT_ALL_ID : this.selected.map(items => items.id)
      apiClient.post(this.getActionPath, {
        action_name: action.action,
        list_ids: listIds,
        filters: Object.assign({}, this.filters, this.hiddenFilters)
      }).then(response => {
        if (Object.keys(CONTENT_TYPE_EXTENSION_MAP).includes(response.headers['content-type'])) {
          let fileName = ''
          if ('content-disposition' in response.headers) {
            try {
              let disposition = contentDisposition.parse(response.headers['content-disposition'])
              fileName = (disposition.parameters && disposition.parameters.filename) ? disposition.parameters.filename : undefined
            } catch (e) {
              console.error('Install content-disposition in the project.')
            }
          }
          this.createAndDownloadFile(fileName, response.data, response.headers['content-type'])
          content['status'] = 'success'
          content['messages'] = this.$t('dialogs.successDownload')
          content['avoid_reload'] = true
        } else {
          content['status'] = response.data['level']
          content['messages'] = response.data['message']
          content['redirect_route_name'] = response.data['redirect_route_name']
        }
        this.$emit('actionApplied', content)
        if (response.data.hasOwnProperty('redirect_route_name')) {
          this.redirect(response.data)
        }
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.loadingAction = false
      })
    },
    denyAction () {
      this.loadingAction = false
      this.loader = null
    },
    redirect (actionResponseData) {
      let redirectInfo = {
        name: actionResponseData['redirect_route_name']
      }
      if ('redirect_route_url_params' in actionResponseData) {
        redirectInfo['params'] = actionResponseData['redirect_route_url_params']
      }
      if ('redirect_route_query_params' in actionResponseData) {
        redirectInfo['query'] = actionResponseData['redirect_route_query_params']
      }
      this.$router.push(redirectInfo)
    },
    createAndDownloadFile (fileName, data, contentType) {
      let confirmedfileName = this.getFileName(fileName, contentType)
      let blob = this.generateBlob(data, contentType)
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = confirmedfileName
      link.click()
    },
    getFileName (fileName, contentType) {
      return fileName || `${this.getDateNow()}.${CONTENT_TYPE_EXTENSION_MAP[contentType]}`
    },
    getDateNow () {
      const d = Date.now()
      let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
      let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
      let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
      let ho = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(d)
      let mi = new Intl.DateTimeFormat('en', { minute: 'numeric' }).format(d)
      let se = new Intl.DateTimeFormat('en', { second: 'numeric' }).format(d)

      return ye + mo + da + ho + mi + se
    },
    selectAllItems () {
      this.$emit('selectOrDeselectAllDataTable')
    },
    clearSelectAllWithItems () {
      this.$emit('clearSelectAllWithItems')
    },
    generateBlob (data, contentType) {
      if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        data = this.base64toBlob(data, contentType)
      } else {
        data = [data]
      }
      return new Blob(data, { type: contentType })
    },
    base64toBlob (base64Data, contentType, sliceSize = 512) {
      const byteCharacters = atob(base64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      return byteArrays
    }
  }
}
</script>

<style scoped>
.mw-400 {
  max-width: 400px;
}
.cus-pt {
  padding-top: 12px;
}

.height-40 {
  height: 40px;
}
</style>
