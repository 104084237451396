<template>
  <v-edit-dialog
    large
    persistent
    @save="save"
    cancel-text="Cancelar"
    save-text="Aceptar"
  >
    <div class="blue--text blue--darken-2 font-weight-bold">
      <SimpleCell :value="realValue" :type="type" :formatNumber="formatNumber" :numberSeparator="numberSeparator"/>
    </div>
    <template v-slot:activator>
      <div class="mt-4 title">Actualizar {{name}}</div>
    </template>
    <template v-slot:input>
      <div class="mt-4 title">Actualizar {{name}}</div>
    </template>
    <template v-slot:input>
      <v-text-field
        :placeholder="String(value||'Introduce el valor')"
        v-model="internalValue"
        :rules="[ validateRule ]"
        label="Edit"
        single-line
        counter
        autofocus
        style="font-weight: bold"
      />
    </template>
  </v-edit-dialog>
</template>

<script>
import SimpleCell from './SimpleCell'
import { isUndefined, isNull } from 'lodash'
import { unNumberParse } from '../../mixins/filters'

const commaToDot = v => v.replace(',', '.')

export default {
  name: 'EditableCell',
  components: { SimpleCell },
  props: {
    value: [String, Number, Boolean],
    name: String,
    formatNumber: Boolean,
    numberSeparator: String,
    type: String
  },
  data () {
    return {
      validateRule: v => !isNaN(commaToDot(v)) || (!this.formatNumber || this.$t('dialogs.invalidValue')),
      internalValue: ''
    }
  },
  computed: {
    realValue () {
      if (this.isInvalidValue() || this.value === '') {
        if (this.type === 'string') {
          return '-'
        }
        return this.value
      }
      return this.value
    }
  },
  methods: {
    isInvalidValue () {
      return isUndefined(this.value) || isNull(this.value)
    },
    save: function () {
      if (this.validateRule(this.internalValue) === true) {
        const value = unNumberParse(this.internalValue, this.numberSeparator)
        this.$emit('save', value)
      } else {
        alert(this.$t('dialogs.invalidValue'))
        this.internalValue = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
