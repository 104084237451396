<template>
  <v-menu
    v-model="openCalendar"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-x
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="dateRangeText"
        :disabled="disabled"
        v-bind:label="label"
        prepend-icon="fa-calendar-day"
        v-on="on"
        readonly
      ></v-text-field>
    </template>
    <v-date-picker min="1970-01-01" max="2100-12-31" range no-title light v-bind:value="this.internalRange" @input="onChange">
      <v-spacer></v-spacer>
          <v-btn text color="primary" @click="onCleanDate">{{ $t('general.clean') }}</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DateRangePickerInField',
  props: {
    label: String,
    start: String,
    end: String,
    disabled: Boolean,
    initialValue: String
  },
  created () {
    this.$emit('update:start', this.initialValue.split(',')[0])
    this.$emit('update:end', this.initialValue.split(',')[1])
  },
  data () {
    return {
      openCalendar: false
    }
  },
  computed: {
    internalRange: {
      get () {
        if (this.start) {
          if (this.end) {
            return [this.start, this.end]
          } else {
            return [this.start]
          }
        }
        return []
      },
      // this computed set redirects the internalRange array asignations to envents for (start / end)
      // the vuetify range picker uses an array as model with length 1 (only start) or 2 (range complete)
      set (value) {
        if (value.length === 1) {
          this.$emit('update:start', value[0])
          this.$emit('update:end', '')
        } else if (value.length === 2) {
          this.$emit('update:end', value[1])
        }
      }
    },
    dateRangeText () {
      if (this.internalRange.length > 0) {
        let internalRangeCopy = this.internalRange.slice()
        if (this.internalRange.length === 2) {
          let date_0 = new Date(internalRangeCopy[0]).toISOString().substr(0, 10)
          let date_1 = new Date(internalRangeCopy[1]).toISOString().substr(0, 10)
          if (date_0 > date_1) {
            internalRangeCopy[0] = this.formatDate(date_1)
            internalRangeCopy[1] = this.formatDate(date_0)
          } else {
            internalRangeCopy[0] = this.formatDate(date_0)
            internalRangeCopy[1] = this.formatDate(date_1)
          }
        } else {
          internalRangeCopy.forEach((dateToBeParsed, index) => {
            let date = new Date(dateToBeParsed).toISOString().substr(0, 10)
            internalRangeCopy[index] = this.formatDate(date)
          })
        }
        return internalRangeCopy.join(' - ')
      }
      return ''
    }
  },
  methods: {
    onChange (value) {
      this.internalRange = value
      if (value.length === 2) { // close the calendar and save when the range is completed
        this.openCalendar = !this.openCalendar
        this.$emit('save')
      }
    },
    onCleanDate () {
      this.openCalendar = !this.openCalendar
      this.$emit('update:start', '')
      this.$emit('update:end', '')
      this.$emit('save')
    },
    formatDate (date) {
      let [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    }
  }
}
</script>

<style scoped>

</style>
