<template>
  <v-form ref="form">
    <BreadcrumbsField v-if="breadcrumbs" :items="breadcrumbs" :adminUrl="adminUrl"/>

    <div class="d-flex">
      <h1 v-if="title" class="display-1 ml-2">{{ title }}</h1>

      <v-tooltip bottom v-if="adminUrlCustomView">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :href="adminUrlCustomView" target="_blank" color="purple" outlined class="elevation-1 ml-3">
            <v-icon>fa-cubes</v-icon>
          </v-btn>
        </template>
        <span>Custom View Admin</span>
      </v-tooltip>
    </div>

    <div style="clear:both;"></div>

    <slot name="before-content"></slot>

    <CustomView
      style="max-width: 100%;"
      class="pt-0"
      v-if="customViewShow"
      :view-name="customViewName"
      :value="value"
      :options="customOptions"
      :custom-component-catalog="customComponentCatalog"
      @input="$emit('input', $event)"
      @adminUrlCustomView="getAdminUrlCustomView"
    />
    <v-layout>
      <template v-for="(action, index) in actions">
        <v-spacer v-if="action.floatEnd"
                  :key="`${action.label}-spacer`"
        ></v-spacer>
        <v-btn
          v-if="action.showButton || action.showButton === undefined"
          :key="index" @click="performAction(action)"
          :loading="isActionLoading(action.label)"
          :disabled="loading && !isActionLoading(action.label)"
          class="ma-2" :color="action.color" :dark="!loading && action.dark"
        >
          <v-icon
            left
            dark v-if="action.icon">{{ action.icon }}</v-icon>
          {{ action.label }}
        </v-btn>
      </template>

      <DialogConfirmation
        v-bind="actionSelected.confirmOptions"
        :titleDialog="getConfirmTitleDialog" :textDialog="getConfirmTextDialog" v-model="showDialogConfirmation"
        v-on:acceptCondition="onAcceptCondition" v-on:denyCondition="showDialogConfirmation = false"
      >
        <template v-slot:confirm-text>
          <slot name="confirm-text"></slot>
        </template>
      </DialogConfirmation>
    </v-layout>

  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import CustomView from '@/lib/uncustomui/components/CustomView'
import DialogConfirmation from '@/lib/uncrudtable/components/forms/DialogConfirmation.vue'
import BreadcrumbsField from './forms/BreadcrumbsField'
import { createNotification } from '@/lib/unnotificationsqueue'
import { RESPONSE_LEVEL } from '@/variables'

export default {
  name: 'CustomLayout',
  components: {
    BreadcrumbsField,
    CustomView,
    DialogConfirmation
  },

  props: {
    value: { type: Object, default: () => {} },
    /** Heading props */
    title: { type: String, default: null },
    /** Breadcrumbs props */
    breadcrumbs: { type: Array, default: null },
    /** CustomView props */
    customViewShow: { type: Boolean, default: true },
    customViewName: { type: String, required: true },
    customOptions: { type: Object, default: () => {} },
    customComponentCatalog: { type: Object, default: () => {} },
    /** Action props
     * { label: String, method: Function, args?: Object, validate?: Boolean, confirm?: Boolean
     *   icon?: String, color?: String, dark?: Boolean, floatEnd?: Boolean,
     *   confirmOptions: { titleDialog, textDialog, confirmText, confirmColor, cancelText } }
     * */
    actions: { type: Array, required: true },
    actionSelected: { type: Object, default: () => {} }, /** Always sync prop as an empty object (not a null value) */
    loading: { type: Boolean, required: true },
    adminUrl: { type: String }
  },

  data () {
    return {
      showDialogConfirmation: false,
      adminUrlCustomView: null
    }
  },

  computed: {
    getConfirmTitleDialog () {
      return (this.actionSelected.confirmOptions && this.actionSelected.confirmOptions.titleDialog) ||
        this.actionSelected.label ||
        this.$t('customActions.operationQuestion')
    },
    getConfirmTextDialog () {
      return (this.actionSelected.confirmOptions && this.actionSelected.confirmOptions.textDialog) ||
        this.$t('customActions.operationQuestion')
    }
  },

  beforeDestroy () {
    this.dismissNotifications()
  },

  methods: {
    ...mapActions({
      addNotification: 'addNotification',
      dismissNotifications: 'dismissNotifications'
    }),

    getAdminUrlCustomView (url) {
      this.adminUrlCustomView = url
    },

    isActionLoading (actionLabel) {
      return this.loading && this.actionSelected && (this.actionSelected.label === actionLabel)
    },

    performAction (action) {
      if (action.validate) {
        if (this.$refs.form.validate()) {
          this.confirmAction(action)
        } else {
          this.addNotification(createNotification(this.$t('dialogs.errorFormData'), RESPONSE_LEVEL.ERROR))
        }
      } else {
        this.confirmAction(action)
      }
    },

    confirmAction (action) {
      this.$emit('update:action-selected', action)
      if (action.confirm) {
        this.showDialogConfirmation = true
      } else {
        action.method(action.args || null)
      }
    },

    onAcceptCondition () {
      this.showDialogConfirmation = false
      this.actionSelected.method(this.actionSelected.args || null)
    }
  }
}
</script>
