<template>
  <div class="short_chip">
    <v-chip small v-if="value">{{value}}</v-chip>
  </div>
</template>

<script>
export default {
  name: 'ChipCell',

  props: {
    value: String
  }
}
</script>

<style scoped>
.short_chip{
  padding:1px;
}
</style>
